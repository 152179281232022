import { mysql } from "./constants/mysql";
import { pgsql } from "./constants/pgsql";
import { redshift } from "./constants/redshift";
import { snowflake } from "./constants/snowflake";
import { oracle } from "./constants/oracle";
import { sqlserver } from "./constants/sqlserver";
import { MdFunctions } from "react-icons/md";
import dateFormat from "dateformat";
export const SUGGESTIONS_MAP = {
  mysql: mysql,
  pgsql: pgsql,
  redshift: redshift,
  snowflake: snowflake,
  sqlserver: sqlserver,
  oracle: oracle,
};
export const CHARACTER_NOT_ALLOWED = [
  ";",
  "(",
  ")",
  "{",
  "}",
  "[",
  "]",
  " ",
  ",",
  "paren",
];
export const CUSTOM_TOKENS_REGEX = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;
export const CUSTOM_TOKENS_REGEX_WITH_QUOTES =
  /"{{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}}"/gm;
export const HELPER_FUNCTION_REGEX =
  /^(AVG|AVG_LIST|DAY|DATEDIFF|DATEPARSE|DATEFORMAT|DATECOMPUTE|EMI|FILTER|DISTINCT|HOUR|LIMIT|MAX|MAX_LIST|MIN|MIN_LIST|MINUTE|MONTH|NOW|PMT|POW|PV|SECOND|SORT|SQRT|SUM|SUM_LIST|TODAY|WEEK|YEAR|COUNT)\(([^)]+)(?:,([^)]+))?\)$/;

export const CUSTOM_SQL_COMPLETERS = ["mysql", "pgsql", "sqlserver"];
export const EDITOR_OPTIONS = {
  enableSnippets: true,
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  tabSize: 2,
  showGutter: true,
  fontSize: "13px",
  wrap: true,
  useWorker: false,
};
export const MODULE_MAP = {
  mysql: "mysql",
  oracle: "mysql",
  mongodb: "mongo",
  pgsql: "pgsql",
  snowflake: "pgsql",
  json: "json5",
  list: "json5",
  js: "js",
  formula: "js",
  text: "text",
  sqlserver: "sqlserver",
};
export const SQL = {
  mysql: "mysql",
  pgsql: "pgsql",
  redshift: "redshift",
  snowflake: "snowflake",
  bigquery: "bigquery",
  sqlserver: "sqlserver",
  oracle: "oracle",
};
export const CUSTOM_DATA_TYPES = {
  customInput: {
    type: "map",
    value: "{}",
  },
  globalVar: {
    type: "map",
    value: "{}",
  },
  outputData: {
    type: "map",
    value: "{}",
  },
  additionalData: {
    type: "map",
    value: "{}",
  },
  dataSet: {
    type: "map",
    value: "{}",
  },
  outputDataList: {
    type: "array",
    value: "[]",
  },
};

export const editorTooltip = {
  js: `<b>You can use Javascript Functions to:</b>\n1. Use input, database etc. attributes in tokenised form (ex. <span class="nected-tokens">{{customInput.attributeName}}</span>)\n2. Compare any custom conditions using simple JS formulas, however this should always return boolean ( ex. <span class="nected-tokens">{{dataSet.attributeName}}</span> > <span class="nected-tokens">{{outputdata.attributeName}}</span>)\n`,
  formula: `<b>You can use Formula Functions to:</b>\n1. Use input, database etc. attributes in tokenised form (ex. <span class="nected-tokens">{{customInput.attributeName}}</span>)\n2. Compare any custom conditions using simple JS formulas, however this should always return boolean ( ex. <span class="nected-tokens">{{dataSet.attributeName}}</span> > <span class="nected-tokens">{{outputdata.attributeName}}</span>)\n`,
  json: `This is a standard JSON. which supports input params, database attributes etc. as tokensied atrributes.\n Allowed list of token is available at the above link. \n`,
  list: `This is a standard List/Array. which supports input params, database attributes etc. as tokensied atrributes.\n Allowed list of token is available at the above link. \n`,
};

const getDefaultDate = (date) => {
  if (!(date instanceof Date)) {
    throw new Error("Input is not a valid Date object");
  }
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  return year + "-" + month + "-" + day;
};

export const DATA_TYPES_VALUE = {
  string: "test_string",
  integer: 1,
  numeric: 1,
  number: 1,
  dateTime: `${new Date().toISOString()}`,
  date: `${getDefaultDate(new Date())}`,
  boolean: true,
  object: "{}",
  map: "{}",
  json: "{}",
  list: "[]",
  array: "[]",
  generic: null,
  "array[strings]": "[]",
  "array[integers]": "[]",
  "array[numeric]": "[]",
  "array[numbers]": "[]",
  "array[dateTime]": "[]",
  "array[dates]": "[]",
  "array[boolean]": "[]",
  "array[objects]": "[]",
};

export const collateSuggestions = (
  isReadOnly = false,
  payload,
  editorMode,
  methods
) => {
  const suggestions = [];
  let updatedKeys = [];
  if (editorMode && SUGGESTIONS_MAP[editorMode]) {
    const keys = Object.keys(SUGGESTIONS_MAP[editorMode]);
    if (methods.includes("write")) {
      updatedKeys = keys.filter((i) => i !== "readKeywords");
    } else {
      updatedKeys = keys.filter((i) => i !== "keywords");
    }
  }
  updatedKeys.forEach((i) => {
    SUGGESTIONS_MAP[editorMode][i].forEach((j) => {
      suggestions.push({
        name: j,
        value: j,
        meta: i,
        score: 990,
      });
    });
  });
  return [...payload, ...suggestions];
};

export const extractTokens = (inputString) => {
  if (!inputString) {
    return [];
  }
  var regex = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;
  var matches = inputString.match(regex) || [];
  return matches;
};

export const filterGAndSVars = (tokensArray) => {
  return tokensArray.filter(
    (i) => !i.includes(".globalVar.") && !i.includes(".systemVar.")
  );
};

export const sanitizedString = (
  value,
  allAutocomplete,
  globalTokenMap,
  from = ""
) => {
  let updatedValue = value;
  const tokens =
    from === "execute"
      ? filterGAndSVars(extractTokens(value))
      : extractTokens(value);

  tokens.forEach((i) => {
    const tokenValue = globalTokenMap[i]
      ? globalTokenMap[i]
      : allAutocomplete.find((j) => j.value.includes(i));

    if (tokenValue) {
      updatedValue = updatedValue.replace(
        i,
        tokenValue.executedValue !== ""
          ? convertValues(
              detectType(tokenValue.executedValue),
              tokenValue.executedValue,
              tokenValue.meta
            )
          : DATA_TYPES_VALUE[tokenValue.meta]
      );
    } else {
      Object.keys(CUSTOM_DATA_TYPES).forEach((element) => {
        const token = i.split(".")[1];
        if (token === element) {
          updatedValue = updatedValue.replace(
            i,
            CUSTOM_DATA_TYPES[element].value
          );
        }
      });
      updatedValue = updatedValue.replace(i);
    }
  });

  return updatedValue;
};

export const sanitizedSQLString = (value, allAutocomplete) => {
  let updatedValue = value;
  const tokens = extractTokens(value);
  tokens.forEach((i) => {
    updatedValue = updatedValue.replace(i, "test_name");
  });
  log(`Sanitized SQL Value : ${updatedValue}`);
  return updatedValue;
};

export const convertValues = (type, value, meta) => {
  if (type === "array" || type === "object") {
    return JSON.stringify(value, null);
  } else {
    return value;
  }
};

export const checkIfDate = (inputString) => {
  if (typeof inputString === "string") {
    // Regular expressions for different date formats
    const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
    const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
    const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
    const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

    const dateTimeFormat1 =
      // eslint-disable-next-line
      /^(\d{2})[\/-](\d{2})[\/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
    const dateTimeFormat2 =
      // eslint-disable-next-line
      /^(\d{4})[\/-](\d{2})[\/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
    // eslint-disable-next-line
    const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
    // eslint-disable-next-line
    const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss
    // eslint-disable-next-line

    const dateTimeFormat5 =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ
    const dateTimeTZFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ
    // eslint-disable-next-line
    const rfc3339Format =
      /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

    // Helper function to check if a date is valid
    const isValidDate = (date) => !isNaN(date.getTime());

    // Function to parse and validate the date, if invalid, return original input
    const parseAndValidateDate = (input, type) => {
      const date = new Date(input);
      return isValidDate(date)
        ? { value: date, type }
        : { value: input, type: "string" };
    };

    // Check various date formats and validate
    if (dateFormat1.test(inputString)) {
      return parseAndValidateDate(inputString, "date");
    } else if (dateFormat2.test(inputString)) {
      return parseAndValidateDate(inputString, "date");
    } else if (dateFormat3.test(inputString)) {
      return parseAndValidateDate(inputString, "date");
    } else if (dateFormat4.test(inputString)) {
      return parseAndValidateDate(inputString, "date");
    } else if (dateTimeFormat1.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (dateTimeFormat2.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (dateTimeFormat3.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (dateTimeFormat4.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (dateTimeFormat5.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (dateTimeTZFormat.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else if (rfc3339Format.test(inputString)) {
      return parseAndValidateDate(inputString, "datetime-local");
    } else {
      return { value: inputString, type: "string" }; // Return the original input if no match
    }
  } else {
    return { value: inputString, type: typeof inputString }; // Return the original input if it's not a string
  }
};

export const detectType = (value) => {
  if (value === null || value === "null") {
    return "null";
  }
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return "array";
    }
    return "object";
  }

  if (typeof value === "string") {
    const date = checkIfDate(value);
    return date.type;
  }

  return typeof value;
};

export const sqlPrettierOptions = {
  tabWidth: 2,
  keywordCase: "upper",
  linesBetweenQueries: 2,
};

export const removeComments = (jsCode) => {
  var singleLineCommentRegex = /\/\/.*/g;
  var multiLineCommentRegex = /\/\*[\s\S]*?\*\//g;
  if (jsCode && typeof jsCode === "string") {
    jsCode = jsCode.replace(singleLineCommentRegex, "");
    jsCode = jsCode.replace(multiLineCommentRegex, "");
  }
  return jsCode;
};

export const removeExtraQuotes = (input) => {
  const tokenPattern = /"{{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}}"/gm;
  const output = input.replace(tokenPattern, (match) => {
    if (
      match.startsWith('"{{') &&
      match.endsWith('}}"') &&
      input[input.indexOf(match) + match.length] === '"'
    ) {
      return match.slice(1, -1);
    }
    return match;
  });

  return output;
};

window.enableLogging = () => {
  if (window.localStorage.getItem("debug") === "enabled") {
    log("Debug mode already enabled.");
  } else {
    window.localStorage.setItem("debug", "enabled");
    log("Debug mode enabled.");
  }
};
window.disableLogging = () => {
  if (window.localStorage.getItem("debug") === "disabled") {
    console.log(
      `%cEditor%c Debug mode already disabled. If you want to enable it call window.enableLogging()`,
      "text-align:center;display: inline-block ; background-color: #000 ; color: #ffffff ; font-weight: bold ; padding: 3px ; border-radius: 3px 3px 3px 3px ;",
      "padding : 3px; display:inline-block;"
    );
  } else {
    window.localStorage.setItem("debug", "disabled");
    console.log(
      `%cEditor%c Debug mode disabled`,
      "text-align:center;display: inline-block ; background-color: #000 ; color: #ffffff ; font-weight: bold ; padding: 3px ; border-radius: 3px 3px 3px 3px ;",
      "padding : 3px; display:inline-block;"
    );
  }
};

export const log = (message) => {
  if (window.localStorage.getItem("debug") === "enabled") {
    console.log(
      `%cEditor%c ${message}`,
      "text-align:center;display: inline-block ; background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 3px ; border-radius: 3px 3px 3px 3px ;",
      "padding : 3px; display:inline-block;"
    );
  }
};

export async function executeJS(payload) {
  const accessToken = window.localStorage.getItem("accessToken");
  const workspaceUUID = window.localStorage.getItem("workspaceUUID");

  const headers = {
    Authorization: accessToken,
    "Nected-WS": workspaceUUID,
  };

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload), // Assuming 'payload' is your request body
  };

  try {
    const apiURL = {
      REACT_APP_EXECUTE_JS_API: process.env.REACT_APP_EXECUTE_JS_API,
    };
    const response = await fetch(
      apiURL.REACT_APP_EXECUTE_JS_API,
      requestOptions
    );
    if (!response.ok) {
      // Handle error here if needed
      const responseData = await response.json();
      throw responseData;
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
}

export const METHODS = ["GET", "POST", "PUT", "PATCH", "DELETE"];
export const EXCLUDE_CONTENT_TYPES = ["GET", "DELETE"];
export const CONTENT_TYPES = [
  "application/json",
  "application/xml",
  "text/csv",
  "text/html",
  "text/plain",
  // 'multipart/form-data',
];
export const CONTENT_TYPE_STRING = [
  "application/xml",
  "text/csv",
  "text/html",
  "text/plain",
];

export const returnBodyType = (contentType) => {
  if (CONTENT_TYPE_STRING.includes(contentType)) {
    return "''";
  } else {
    return "{}";
  }
};

export const CALL_REST_API_TEMPLATE = `
/**
* #####   START OF REST API PAYLOAD #####
*/
    var $$CONNECTOR_NAME$$Payload = {
        'payloadBody' :{
            // "key" : "value"
        },
        'queryParams':{
            // "key" : "value"
        },
        'headers':{
            $$CONNECTOR_CONTENT_TYPE$$
            // you can add headers as needed
        },
        'apiMethod' : '$$CONNECTOR_METHOD$$',
        'apiPath' : '' // specify the api path | base URL will be same as what you have entered while creating connector
    };

/**
* #####   END OF REST API #####
*/

rest_api_connector( '$$CONNECTOR_FULLNAME$$' , $$CONNECTOR_NAME$$Payload.apiPath , $$CONNECTOR_NAME$$Payload.apiMethod , $$CONNECTOR_NAME$$Payload.headers, $$CONNECTOR_NAME$$Payload.queryParams);`;

export const stringToCamelCase = (inputString) => {
  return inputString
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join("");
};

export const extractCallRestApiInfo = (multilineString) => {
  const lines = multilineString.split("\n");
  const result = [];

  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const line = lines[lineNumber].trim();
    const matches = line.match(/rest_api_connector\((.*?)\)/g);

    if (matches) {
      matches.forEach((match) => {
        const args = match
          .match(/\((.*?)\)/)[1]
          .split(",")
          .map((arg) => arg.trim())
          .map((i) => i.split("=")[0]);
        const numArguments = args.length;

        if (numArguments >= 6) {
          result.push({
            lineNumber: lineNumber + 1, // Line numbers are 1-based
            numArguments: numArguments,
            arguments: args,
          });
        }
      });
    }
  }

  return result;
};

export const validateFunctionSignature = (multilineString) => {
  const lines = multilineString.split("\n");
  const signatureErrors = [];

  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const line = lines[lineNumber].trim();

    // Check if the line contains a "rest_api_connector" function call
    if (line.includes("rest_api_connector(")) {
      // Match "rest_api_connector" function calls and extract arguments
      const match = line.match(/rest_api_connector\(([^)]*)\)/);

      if (match) {
        const args = match[1].split(",").map((arg) => arg.trim());

        // Define the expected arguments in the correct order
        const expectedArgs = [
          "connectorName",
          "urlPath",
          "method",
          "body",
          "headers",
          "queryParams",
        ];

        let missingArgs = [];
        let validSignature = true;
        let numArguments = 0;
        // Create a Set of provided argument names
        const providedArgNames = new Set(
          args.map((arg) => arg.split("=")[0].trim())
        );
        let isGETMethod = args.map((i) => i.includes("'GET'")).includes(true);
        for (const expectedArg of expectedArgs) {
          if (!providedArgNames.has(expectedArg)) {
            //   if (expectedArg === 'body' && providedArgNames.has('method') && isGETMethod === true) {
            //     // Skip 'body' validation when 'method' is 'GET'
            //     numArguments = args.length - 1;
            //     isGETMethod = true;
            //     continue;
            //   }
            missingArgs.push(expectedArg);
            numArguments = args.length;
            validSignature = false;
          }
        }

        if (!validSignature) {
          signatureErrors.push({
            lineNumber: lineNumber + 1,
            numArguments: numArguments,
            missingArguments: missingArgs,
            validSignature: false,
            isGETMethod,
          });
        }
      }
    }
  }

  return signatureErrors;
};

export const validatehelperFunctionSignature = (multilineString) => {
  const lines = multilineString.split("\n");
  const signatureErrors = [];
  const functionNames = ["AVG_LIST", "SUM_LIST", "MIN_LIST", "MAX_LIST"];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    functionNames.forEach((functionName) => {
      const functionNameIndex = line.indexOf(functionName);
      if (functionNameIndex !== -1) {
        const startIndex = line.indexOf("(", functionNameIndex);
        if (startIndex !== -1) {
          const endIndex = line.indexOf(")", startIndex);
          if (endIndex !== -1) {
            const signature = line.substring(startIndex + 1, endIndex);
            const args = signature.split(",");
            if (args.length === 2) {
              const listArg = args.find((arg) =>
                arg.trim().startsWith("list=")
              );
              const keyNameArg = args.find((arg) =>
                arg.trim().startsWith("keyName=")
              );
              if (listArg && keyNameArg) {
                const listValue = listArg.split("=")[1].trim();
                const keyNameValue = keyNameArg.split("=")[1].trim();
                if (
                  (listValue === "select_list_token" &&
                    keyNameValue === "select_key") ||
                  (listValue.includes("select_list_token") &&
                    keyNameValue.includes("select_key")) ||
                  (listValue === "[]" && keyNameValue === "select_key")
                ) {
                  signatureErrors.push({
                    functionName: functionName,
                    lineNumber: i + 1,
                    errorMessage: "Invalid function signature",
                    validSignature: false,
                  });
                }
              } else {
                signatureErrors.push({
                  functionName: functionName,
                  lineNumber: i + 1,
                  errorMessage: "Invalid number of arguments",
                  validSignature: false,
                });
              }
            } else {
              signatureErrors.push({
                functionName: functionName,
                lineNumber: i + 1,
                errorMessage: "Invalid number of arguments",
                validSignature: false,
              });
            }
          }
        }
      }
    });
  }

  return signatureErrors;
};

export const validateListMacrosSignature = (input) => {
  const functions = ["AVG_LIST", "MIN_LIST", "MAX_LIST", "SUM_LIST"];
  const tokenRegex = /{{\.\w+\.\w+}}/;
  const listRegex = /^\[.*\]$/;
  const stringRegex = /^".*"$/;
  const selectListTokenRegex = /^select_list_token$/;
  const selectDateTokenRegex = /^select_date_token$/;
  const selectUnitTokenRegex = /^select_unit$/;
  const errors = [];

  const validateArgs = (args, lineNumber, functionName, startIndex) => {
    // Ensure the first argument is properly formatted as an array
    if (
      args.length < 1 ||
      !(listRegex.test(args[0]) || tokenRegex.test(args[0]) || args[0] === "[]")
    ) {
      errors.push({
        lineNumber,
        position: startIndex,
        errorMessage: "Expected list, or list token as first argument",
        functionName,
      });
    }

    // Specific check for empty string passed as list
    if (args[0] === '""') {
      errors.push({
        lineNumber,
        position: startIndex,
        errorMessage:
          "Invalid list argument; expected an list, got an empty string",
        functionName,
      });
    }

    // Check for select_list_token
    if (args.length >= 1 && selectListTokenRegex.test(args[0])) {
      errors.push({
        lineNumber,
        position: startIndex,
        errorMessage: "Click on select_list_token to select token",
        functionName,
      });
    }

    if (args.length >= 1 && selectDateTokenRegex.test(args[0])) {
      errors.push({
        lineNumber,
        position: startIndex,
        errorMessage: "Click on select_date_token to select token",
        functionName,
      });
    }

    if (args.length >= 1 && selectUnitTokenRegex.test(args[0])) {
      errors.push({
        lineNumber,
        position: startIndex,
        errorMessage: "Click on select_unit to select unit",
        functionName,
      });
    }

    // Validate second argument as string or token, if present
    if (
      args.length > 1 &&
      !stringRegex.test(args[1]) &&
      !tokenRegex.test(args[1])
    ) {
      errors.push({
        lineNumber,
        position: startIndex + args[0].length + 1, // Adjust for comma
        errorMessage: "Expected string or string token at second argument",
        functionName,
      });
    }
  };

  const parseAndValidate = (line, lineNumber) => {
    const regex = new RegExp(
      `(${functions.join(
        "|"
      )})\\(list=(\\[.*?\\]|{{\\..+?\\..+?}}|select_list_token|""?)(,keyName=(".*?"|{{\\..+?\\..+?}}))?\\)`,
      "g"
    );
    let match;
    while ((match = regex.exec(line)) !== null) {
      const functionName = match[1];
      const args = [match[2]];
      if (match[4]) args.push(match[4]);

      validateArgs(args, lineNumber, `${functionName}()`, match.index);
    }
  };

  input.split("\n").forEach((line, index) => {
    parseAndValidate(line, index + 1); // Handle each line individually, providing the correct line number
  });

  return errors;
};

export const validateMacrosSignature = (input) => {
  const errors = [];
  const lines = input.split("\n");

  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const line = lines[lineNumber].trim();
    if (!line) continue; // Skip empty lines

    const signature = line.split("(")[0].trim(); // Extracting the signature type
    const argsString = line
      .substring(line.indexOf("(") + 1, line.lastIndexOf(")"))
      .trim();

    if (isMacroFunction(signature)) {
      // Check for exact match
      if (argsString !== "") {
        const args = parseArgs(argsString);

        for (let i = 0; i < args.length; i++) {
          const arg = args[i];
          if (!isValidArgument(arg)) {
            errors.push({
              lineNumber: lineNumber + 1,
              functionName: signature,
              errorMessage: `Invalid argument at position ${i + 1}`,
            });
            break; // Stop checking further arguments for this line
          }
        }
      }
    }
  }

  return errors;
};

const ALL_MACROS = [
  "AVG",
  "SUM",
  "MIN",
  "MAX",
  "DAY",
  "MONTH",
  "YEAR",
  "HOUR",
  "SECOND",
  "NOW",
  "TODAY",
  "MAX_LIST",
  "MIN_LIST",
  "FILTER",
  "DISTINCT",
  "LIMIT",
  "SORT",
  "SQRT",
  "POW",
  "PMT",
  "PV",
  "EMI",
  "DATEDIFF",
  "DATECOMPUTE",
  "DATEFORMAT",
  "DATEPARSE",
  "MINUTE",
  "WEEK",
  "COUNT",
];

export const isMacroFunction = (type) => {
  return ALL_MACROS.includes(type);
};

export const extractMacros = (inputString) => {
  const matchedMacros = [];
  ALL_MACROS.forEach((macro) => {
    const regex = new RegExp(
      `(?:^|[^\\w])${escapeRegExp(macro)}(?:[^\\w]|$)`,
      "gi"
    ); // 'g' for global search, 'i' for case insensitive
    if (regex.test(inputString)) {
      matchedMacros.push(macro);
    }
  });
  return matchedMacros.length > 0 ? matchedMacros : [];
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters in regex
}

const isValidArgument = (arg) => {
  return (
    !isNaN(arg) ||
    arg === "true" ||
    arg === "false" ||
    (Array.isArray(arg) && arg.every((subArg) => isValidArgument(subArg)))
  );
};

const parseArgs = (argsString) => {
  const args = [];
  let currentArg = "";
  let depth = 0;

  for (let i = 0; i < argsString.length; i++) {
    const char = argsString[i];
    if (char === "(") {
      depth++;
    } else if (char === ")") {
      depth--;
    } else if (char === "," && depth === 0) {
      args.push(currentArg.trim());
      currentArg = "";
      continue;
    }
    currentArg += char;
  }

  if (currentArg.trim() !== "") {
    args.push(currentArg.trim());
  }

  return args.map((arg) => {
    if (arg.startsWith("[") && arg.endsWith("]")) {
      return arg
        .substring(1, arg.length - 1)
        .split(",")
        .map((subArg) => subArg.trim());
    } else {
      return arg;
    }
  });
};

export const extractConnectorName = (inputString) => {
  const pattern = /connectorName=['"]([^'"]+)['"]/;
  const match = inputString.match(pattern);
  if (match && match.length > 1) {
    return match[1];
  } else {
    return null;
  }
};

export const functionSignatures = {
  SUM_LIST: {
    signature: "SUM_LIST(values: number[] | Array<T>, key?: string): number",
    description: `
  Sums the values in a list.
  @param {number[] | Array<T>} values - List of numbers or list of objects.
  @param {string} [key] - Key name if list of objects is passed.
  @returns {number} - The sum of the values.
`,
  },
  AVG_LIST: {
    signature: "AVG_LIST(values: number[] | Array<T>, key?: string): number",
    description: `
  Calculates the average of values in a list.
  @param {number[] | Array<T>} values - List of numbers or list of objects.
  @param {string} [key] - Key name if list of objects is passed.
  @returns {number} - The average of the values.
`,
  },
  MIN_LIST: {
    signature: "MIN_LIST(values: number[] | Array<T>, key?: string): number",
    description: `
  Finds the minimum value in a list.
  @param {number[] | Array<T>} values - List of numbers or list of objects.
  @param {string} [key] - Key name if list of objects is passed.
  @returns {number} - The minimum value.
`,
  },
  MAX_LIST: {
    signature: "MAX_LIST(values: number[] | Array<T>, key?: string): number",
    description: `
  Finds the maximum value in a list.
  @param {number[] | Array<T>} values - List of numbers or list of objects.
  @param {string} [key] - Key name if list of objects is passed.
  @returns {number} - The maximum value.
`,
  },
  SUM: {
    signature: "SUM(values: number|boolean): number",
    description: `
  Sums the values.
  @param {number|boolean} values - Comma separated values or tokens of type number or boolean.
  @returns {number} - The sum of the values.
`,
  },
  AVG: {
    signature: "AVG(values: number|boolean): number",
    description: `
  Calculates the average of values.
  @param {number|boolean} values - Comma separated values or tokens of type number or boolean.
  @returns {number} - The average of the values.
`,
  },
  COUNT: {
    signature: "COUNT(list): number",
    description: `
  Calculates the length of list/array.
  @param {list} values - pass list or array.
  @returns {number} - The count.
`,
  },
  MIN: {
    signature: "MIN(values: number|boolean): number",
    description: `
  Finds the minimum value.
  @param {number|boolean} values - Comma separated values or tokens of type number or boolean.
  @returns {number} - The minimum value.
`,
  },
  MAX: {
    signature: "MAX(values: number|boolean): number",
    description: `
  Finds the maximum value.
  @param {number|boolean} values - Comma separated values or tokens of type number or boolean.
  @returns {number} - The maximum value.
`,
  },
  FILTER: {
    signature:
      "FILTER(list: Array<T>, condition: (item: T) => boolean, keyToBeReturned?",
    description: `
  Filters a list based on a condition.
  @param {Array<T>} list - List of objects.
  @param {(item: T) => boolean} condition - Condition to filter the list.
  @param {string} keyToBeReturned1 - If you want only specific key to be returned.
  @returns {Array<T>} - The filtered list.
`,
  },
  LIMIT: {
    signature: "LIMIT(list: Array<T>, limit: number): Array<T>",
    description: `
  Limits the size of a list.
  @param {Array<T>} list - List of objects.
  @param {number} limit - Limit number to restrict the list size.
  @returns {Array<T>} - The limited list.
`,
  },
  DISTINCT: {
    signature: "DISTINCT(list: Array<T>): Array<T>",
    description: `
  Removes the duplicate keys and returns array.
  First occurence of keys would be given priority.
  @param {Array<T>} list - List of objects.
  @returns {Array<T>} - The distinct list.
`,
  },
  SORT: {
    signature:
      "SORT(list: Array<T>, order: 'ASC' | 'DESC',keys?: strings): Array<T>",
    description: `
  Sorts a list by keys.
  @param {Array<T>} list - List of objects.
  @param {'ASC' | 'DESC'} order - Order of sorting (ASC/DESC).
  @param {string} keys - Keys to sort by comma seprated.
  @returns {Array<T>} - The sorted list.
`,
  },
  SQRT: {
    signature: "SQRT(x: number): number",
    description: `
  Finds the square root of a number.
  @param {number} x - A number to find the square root of.
  @returns {number} - The square root of the number.
`,
  },
  POW: {
    signature: "POW(x: number, y: number): number",
    description: `
  Raises a number to the power of another number.
  @param {number} x - Base number.
  @param {number} y - Exponent number.
  @returns {number} - The result of the power operation.
`,
  },
  PMT: {
    signature:
      "PMT(interestRate: number, numberOfPeriods: number, presentValue: number, futureValue?: number, type?: number): number",
    description: `
  Calculates the payment (PMT).
  @param {number} interestRate - The interest rate.
  @param {number} numberOfPeriods - The number of periods.
  @param {number} presentValue - The present value.
  @param {number} [futureValue] - The future value (optional).
  @param {number} [type] - Type (0 for end of period, 1 for beginning, optional).
  @returns {number} - The payment amount.
  `,
  },
  PV: {
    signature:
      "PV(interestRate: number, numberOfPeriods: number, paymentAmount: number, futureValue?: number, type?: number): number",
    description: `
  Calculates the present value (PV).
  @param {number} interestRate - The interest rate.
  @param {number} numberOfPeriods - The number of periods.
  @param {number} paymentAmount - The payment amount.
  @param {number} [futureValue] - The future value (optional).
  @param {number} [type] - Type (0 for end of period, 1 for beginning, optional).
  @returns {number} - The present value.
  `,
  },
  EMI: {
    signature:
      "EMI(loanAmount: number, interestRate : number,numberOfPeriods: number): number",
    description: `
  Calculates the equated monthly installment (EMI).
  @param {number} loanAmount - The loan amount.
  @param {number} interestRate - The interest rate.
  @param {number} numberOfPeriods - The number of periods.
  @returns {number} - The EMI amount.
  `,
  },
  DATEDIFF: {
    signature:
      "DATEDIFF(d1: Date | DateTime, d2: Date | DateTime, unit: string): number",
    description: `
  Calculates the difference between two dates.
  @param {Date | DateTime} d1 - The first date or DateTime.
  @param {Date | DateTime} d2 - The second date or DateTime.
  @param {string} unit - Unit of time (e.g., days, hours).
  @returns {number} - The difference in the specified unit.
`,
  },
  DATECOMPUTE: {
    signature:
      "DATECOMPUTE(d1: Date | DateTime, d2: Date | DateTime, value: string, unit: string): Date | DateTime",
    description: `
  Computes a date by adding/subtracting a value.
  @param {Date | DateTime} d1 - The base date or DateTime.
  @param {Date | DateTime} d2 - The reference date or DateTime.
  @param {string} value - Value to compute (e.g., '+2' or '-2').
  @param {string} unit - Unit of time.
  @returns {Date | DateTime} - The computed date.
`,
  },
  DATEFORMAT: {
    signature:
      "DATEFORMAT(d1: Date | DateTime, format : string): Date | DateTime",
    description: `
  Transform the date.
  @param {Date | DateTime} d1 - The base date or DateTime.
  @param {format} string - Output format of date.
`,
  },
  DATEPARSE: {
    signature:
      "DATEPARSE(d1: Date | DateTime, format : string): Date | DateTime",
    description: `
  Coverts date into nected/platform supported date string (RFC3339).
  @param {Date | DateTime} d1 - The base date or DateTime.
  @param {format} string - Input date string format.
`,
  },
  NOW: {
    signature: "NOW(tz?: string): DateTime",
    description: `
  Returns the current date and time.
  @param {string} [tz] - Timezone (optional).
  @returns {DateTime} - The current date and time.
`,
  },
  TODAY: {
    signature: "TODAY(tz?: string): Date",
    description: `
  Returns the current date.
  @param {string} [tz] - Timezone (optional).
  @returns {Date} - The current date.
`,
  },
  YEAR: {
    signature: "YEAR(d: Date | DateTime): number",
    description: `
  Extracts the year from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The year.
`,
  },
  MONTH: {
    signature: "MONTH(d: Date | DateTime): number",
    description: `
  Extracts the month from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The month.
`,
  },
  WEEK: {
    signature: "WEEK(d: Date | DateTime): number",
    description: `
  Extracts the week number from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The week number.
`,
  },
  DAY: {
    signature: "DAY(d: Date | DateTime): number",
    description: `
  Extracts the day from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The day.
`,
  },
  HOUR: {
    signature: "HOUR(d: Date | DateTime): number",
    description: `
  Extracts the hour from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The hour.
`,
  },
  MINUTE: {
    signature: "MINUTE(d: Date | DateTime): number",
    description: `
  Extracts the minute from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The minute.
`,
  },
  SECOND: {
    signature: "SECOND(d: Date | DateTime): number",
    description: `
  Extracts the second from a date.
  @param {Date | DateTime} d - The date or DateTime.
  @returns {number} - The second.
`,
  },
};

export const helperFunctions = [
  {
    name: "AVG",
    value: "AVG()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "AVG_LIST",
    value: "AVG_LIST(list=select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "COUNT",
    value: "COUNT(select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATECOMPUTE",
    value: "DATECOMPUTE()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEFORMAT",
    value: "DATEFORMAT()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEPARSE",
    value: "DATEPARSE()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEDIFF",
    value: "DATEDIFF()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DAY",
    value: "DAY()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "EMI",
    value: "EMI()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "FILTER",
    value: "FILTER(select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DISTINCT",
    value: "DISTINCT(select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "HOUR",
    value: "HOUR()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "LIMIT",
    value: "LIMIT()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MAX",
    value: "MAX()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MAX_LIST",
    value: "MAX_LIST(list=select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MIN",
    value: "MIN()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MIN_LIST",
    value: "MIN_LIST(list=select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MINUTE",
    value: "MINUTE()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MONTH",
    value: "MONTH()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "NOW",
    value: "NOW()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "PMT",
    value: "PMT()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "POW",
    value: "POW()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "PV",
    value: "PV()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SECOND",
    value: "SECOND()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SORT",
    value: "SORT(select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SQRT",
    value: "SQRT()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SUM",
    value: "SUM()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SUM_LIST",
    value: "SUM_LIST(list=select_list_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "TODAY",
    value: "TODAY()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "WEEK",
    value: "WEEK()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "YEAR",
    value: "YEAR()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DAY",
    value: "DAY(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MONTH",
    value: "MONTH(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "YEAR",
    value: "YEAR(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "MINUTE",
    value: "MINUTE(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "SECOND",
    value: "SECOND(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "NOW",
    value: "NOW()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "TODAY",
    value: "TODAY()",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEDIFF",
    value: "DATEDIFF(select_date_token, select_date_token, select_unit)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATECOMPUTE",
    value:
      "DATECOMPUTE(select_date_token, select_date_token, '+1' , select_unit)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEFORMAT",
    value: "DATEFORMAT(select_date_token)",
    meta: "Macros",
    score: 999,
  },
  {
    name: "DATEPARSE",
    value: "DATEPARSE(select_date_token)",
    meta: "Macros",
    score: 999,
  },
];

export const convertDomains = () => {
  let domains;

  try {
    // Check if it's an array; if not, throw an error to catch as a fallback
    const parsedDomains = {
      REACT_APP_ALLOWED_DOMAINS: process.env.REACT_APP_ALLOWED_DOMAINS,
    };

    if (Array.isArray(JSON.parse(parsedDomains))) {
      domains = JSON.parse(parsedDomains.REACT_APP_ALLOWED_DOMAINS);
    }
  } catch (error) {
    const domainsMap = {
      REACT_APP_ALLOWED_DOMAINS: process.env.REACT_APP_ALLOWED_DOMAINS,
    };
    if (typeof domainsMap === "object") {
      domains = domainsMap.REACT_APP_ALLOWED_DOMAINS.split(",");
    }
  }

  return domains;
};

export const isArrayAsInputValid = (value) => {
  let isArrayValid = true;
  try {
    convertArrayAsInput(value);

    isArrayValid = true;
  } catch (err) {
    isArrayValid = false;
  }

  return isArrayValid;
};

export const convertArrayAsInput = (value) => {
  if (value === null || value === "" || value === undefined) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  const resultingArrayStr = `${sanitizeArrayAsInput(value.toString().trim())
    .replaceAll("|", ",")
    .replaceAll(";", ",")
    .replaceAll("'", '"')}`;

  return convertStringToList(resultingArrayStr);
};

export const sanitizeArrayAsInput = (inputString) => {
  let sanitizedString = inputString;
  const firstChar = inputString[0];
  const lastChar = inputString[inputString.length - 1];

  if (firstChar === "|" || firstChar === "," || firstChar === ";") {
    sanitizedString = inputString.slice(1);
  }

  if (lastChar === "|" || lastChar === "," || lastChar === ";") {
    sanitizedString = inputString.slice(0, -1);
  }

  return sanitizedString;
};

export const convertStringToList = (input) => {
  const tokenRegex = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;
  const tokenMatches = input.match(tokenRegex) || [];

  // Replace tokens with placeholder strings surrounded by double quotes
  const sanitizedInput = tokenMatches.reduce(
    (acc, token) => acc.replace(token, `"${token}"`),
    input
  );

  // Remove square brackets if present at the beginning and end of the string
  let processedInput = sanitizedInput;
  if (processedInput.startsWith("[") && processedInput.endsWith("]")) {
    processedInput = processedInput.slice(1, -1);
  }

  const stack = [];
  let currentItem = "";
  let inQuotes = false;
  let bracesCount = 0;
  let bracketsCount = 0;

  for (let i = 0; i < processedInput.length; i++) {
    const char = processedInput[i];

    if (char === "," && !inQuotes && bracesCount === 0 && bracketsCount === 0) {
      const parsedItem = parseItem(currentItem.trim());
      if (parsedItem !== undefined) {
        stack.push(parsedItem);
      }
      currentItem = "";
    } else {
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (
        char === "{" &&
        !inQuotes &&
        processedInput.slice(i).match(tokenRegex)
      ) {
        let tokenMatch = processedInput.slice(i).match(tokenRegex);
        if (tokenMatch && tokenMatch[0]) {
          stack.push(tokenMatch[0]);
          i += tokenMatch[0].length - 1;
        }
      } else if (char === "{" && !inQuotes) {
        bracesCount++;
      } else if (char === "}" && !inQuotes) {
        bracesCount--;
      } else if (char === "[" && !inQuotes) {
        bracketsCount++;
      } else if (char === "]" && !inQuotes) {
        bracketsCount--;
      }

      currentItem += char;
    }
  }

  const parsedItem = parseItem(currentItem.trim());
  if (parsedItem !== undefined) {
    stack.push(parsedItem);
  }

  return stack;
};

// Old parseItem function
const parseItem = (item) => {
  if (item === "true" || item === "false") {
    return item === "true";
  } else if (!isNaN(item)) {
    return parseFloat(item);
  } else if (item.startsWith("{") || item.startsWith("[")) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // If JSON parsing fails, return the original item
      return item;
    }
  } else if (item.startsWith("'") || item.startsWith('"')) {
    return item.slice(1, -1);
  } else if (item === "null") {
    return null;
  } else {
    return item;
  }
};

export const convertArrayToString = (value) => {
  if (value === "") {
    return "";
  }

  return value
    .map((item) => {
      if (typeof item === "string") {
        if (!isNaN(Number(item))) {
          return `"${item}"`;
        } else {
          return `${item}`;
        }
      } else if (typeof item === "object" && item !== null) {
        return JSON.stringify(item);
      } else if (item === null) {
        return "null";
      } else {
        return item;
      }
    })
    .join("|");
};

export const documentationPropertiesData = [
  // {
  //   category: "Tokens",
  //   formulas: [
  //     {
  //       name: "String",
  //       icon: <VscSymbolString />,
  //       descriptionTitle: "String",
  //       description: "Operations that you can perform on string/text values.",
  //       data: [{}],
  //     },
  //     {
  //       name: "Numeric",
  //       icon: <GoNumber />,
  //       descriptionTitle: "Numeric",
  //       description: "Operations that you can perform on numbers.",
  //       data: [{}],
  //     },
  //     {
  //       name: "Boolean",
  //       descriptionTitle: "Boolean",
  //       icon: <VscSymbolBoolean />,
  //       description: "Operations that you can perform on boolean values.",
  //       data: [],
  //     },
  //     {
  //       name: "Date",
  //       descriptionTitle: "Date",
  //       icon: <CiCalendarDate />,
  //       description: "Operations that you can perform on dates.",
  //       data: [],
  //     },
  //     {
  //       name: "DateTime",
  //       descriptionTitle: "DateTime",
  //       icon: <IoIosTime />,
  //       description: "Operations that you can perform on datetime values",
  //       data: [],
  //     },
  //     {
  //       name: "JSON/Object",
  //       descriptionTitle: "JSON/Object",
  //       icon: <MdOutlineDataObject />,
  //       description: "Operations that you can perform on JSON or objects.",
  //       data: [],
  //     },
  //     {
  //       name: "Array/List",
  //       descriptionTitle: "Array/List",
  //       icon: <MdDataArray />,
  //       description: "Operations that you can perform on Array or list.",
  //       data: [],
  //     },
  //   ],
  // },
  {
    category: "Functions",
    formulas: [
      {
        name: "AVG()",
        icon: <MdFunctions />,
        descriptionTitle: "AVG(values : number | boolean)",
        description: "Returns the average of the all the passed values.",
        data: [],
      },
      {
        name: "AVG_LIST()",
        icon: <MdFunctions />,
        descriptionTitle: "AVG_LIST(values: number[], key?: string)",
        description: "Returns the average of the all the passed list value.",
        data: [],
      },
      {
        name: "DATEDIFF()",
        icon: <MdFunctions />,
        descriptionTitle: "DATEDIFF(date1 , date2 , unit)",
        description: "Calculates the difference in days between two dates.",
        data: [],
      },
      {
        name: "DATECOMPUTE()",
        icon: <MdFunctions />,
        descriptionTitle: "DATECOMPUTE(date , operation , unit)",
        description:
          "Performs date arithmetic, adding or subtracting days, months, or years to a date.",
        data: [],
      },
      {
        name: "DATEFORMAT()",
        icon: <MdFunctions />,
        descriptionTitle: "DATEFORMAT(date: string|dateObj , format : string)",
        description: "Transforms date or datetime into desired format",
        data: [],
      },
      {
        name: "DATEPARSE()",
        icon: <MdFunctions />,
        descriptionTitle: "DATEPARSE(date : string , format : string)",
        description:
          "Transforms date or datetime into nected platform supported date (RFC 3339)",
        data: [],
      },
      {
        name: "DAY()",
        icon: <MdFunctions />,
        descriptionTitle: "DAY(date | dateTime)",
        description: "Returns the day of the month from a given date.",
        data: [],
      },
      {
        name: "DISTINCT()",
        icon: <MdFunctions />,
        descriptionTitle: "DISTINCT(list)",
        description: "Returns the distinct array/list",
        data: [],
      },
      {
        name: "EMI()",
        icon: <MdFunctions />,
        descriptionTitle:
          "EMI(interestRate , numberOfPeriods , loanAmount , futureValue?)",
        description:
          "Calculates the Equated Monthly Installment (EMI) for a loan.",
        data: [],
      },
      {
        name: "FILTER()",
        icon: <MdFunctions />,
        descriptionTitle: "FILTER(list , condition, keysToReturn?)",
        description:
          "Returns the filtered list/array based on provided boolean condition and specific keys",
        data: [],
      },
      {
        name: "LIMIT()",
        icon: <MdFunctions />,
        descriptionTitle: "LIMIT(list, limit)",
        description:
          "Limits the number of elements returned in an array to a specified number",
        data: [],
      },
      {
        name: "HOUR()",
        icon: <MdFunctions />,
        descriptionTitle: "HOUR(date | dateTime)",
        description: "Returns the hour from a given date.",
        data: [],
      },
      {
        name: "MAX()",
        icon: <MdFunctions />,
        descriptionTitle: "MAX(values : number | boolean)",
        description: "Returns the max of the all the passed values.",
        data: [],
      },
      {
        name: "MAX_LIST()",
        icon: <MdFunctions />,
        descriptionTitle: "MAX_LIST(values: number[], key?: string)",
        description: "Returns the max of the all the passed list value.",
        data: [],
      },
      {
        name: "MIN()",
        icon: <MdFunctions />,
        descriptionTitle: "MIN(values : number | boolean)",
        description: "Returns the min of the all the passed values.",
        data: [],
      },
      {
        name: "MIN_LIST()",
        icon: <MdFunctions />,
        descriptionTitle: "MIN_LIST(values: number[], key?: string)",
        description: "Returns the min of the all the passed list value.",
        data: [],
      },
      {
        name: "MINUTE()",
        icon: <MdFunctions />,
        descriptionTitle: "MINUTE(date | dateTime)",
        description: "Returns the minute from a given date.",
        data: [],
      },
      {
        name: "MONTH()",
        icon: <MdFunctions />,
        descriptionTitle: "MONTH(date | dateTime)",
        description: "Returns the month from a given date.",
        data: [],
      },
      {
        name: "NOW()",
        icon: <MdFunctions />,
        descriptionTitle: "NOW(timeZone?)",
        description:
          "Returns the current date and time in current locale or specified timezone.",
        data: [],
      },
      {
        name: "PMT()",
        icon: <MdFunctions />,
        descriptionTitle:
          "PMT(interestRate , numberOfPeriods , presentValue , futureValue? , type?)",
        description:
          "Calculates the Payment (PMT) for a loan based on constant payments and a constant interest rate.",
        data: [],
      },
      {
        name: "POW()",
        icon: <MdFunctions />,
        descriptionTitle: "POW(number , base)",
        description: "Calculates the power of a number.",
        data: [],
      },
      {
        name: "PV()",
        icon: <MdFunctions />,
        descriptionTitle:
          "PV(interestRate , numberOfPeriods , paymentAmount , futureValue? , type?)",
        description:
          "Calculates the Present Value (PV) of an investment or loan.",
        data: [],
      },
      {
        name: "SECOND()",
        icon: <MdFunctions />,
        descriptionTitle: "SECOND(date | dateTime)",
        description: "Returns the second from a given date.",
        data: [],
      },
      {
        name: "SORT()",
        icon: <MdFunctions />,
        descriptionTitle: "SORT(list , order : ('ASC'/'DESC'), key1?)",
        description:
          "Returns sorted list/array based on order and keys provided",
        data: [],
      },
      {
        name: "SQRT()",
        icon: <MdFunctions />,
        descriptionTitle: "SQRT(number)",
        description: "Calculates the square root of a number.",
        data: [],
      },
      {
        name: "SUM()",
        icon: <MdFunctions />,
        descriptionTitle: "SUM(values : number | boolean)",
        description: "Returns the sum of the all the passed values.",
        data: [],
      },
      {
        name: "SUM_LIST()",
        icon: <MdFunctions />,
        descriptionTitle: "SUM_LIST(values: number[], key?: string)",
        description: "Returns the sum of the all the passed list value.",
        data: [],
      },
      {
        name: "COUNT()",
        icon: <MdFunctions />,
        descriptionTitle: "COUNT(list)",
        description: "Returns the length of list/array.",
        data: [],
      },
      {
        name: "TODAY()",
        icon: <MdFunctions />,
        descriptionTitle: "TODAY(timeZone?)",
        description:
          "Returns the current date in current locale or specified timezone.",
        data: [],
      },
      {
        name: "WEEK()",
        icon: <MdFunctions />,
        descriptionTitle: "WEEK(date | dateTime)",
        description: "Returns the week number from a given date.",
        data: [],
      },
      {
        name: "YEAR()",
        icon: <MdFunctions />,
        descriptionTitle: "YEAR(date | dateTime)",
        description: "Returns the year from a given date.",
        data: [],
      },
    ],
  },
];

const StringMethods = [
  {
    methodName: "length",
    methodDesc: "Returns the length of the string",
    codeString: "str.length",
    exampleValue: '"This is test string"',
    exampleReturn: 18,
  },
  {
    methodName: "includes(substring, [start])",
    methodDesc: "Checks if a string contains a specified substring.",
    codeString: 'str.includes("World")',
    exampleValue: '"Hello, World!"',
    exampleReturn: true,
  },
  {
    methodName: "startsWith(substring, [start])",
    methodDesc: "Checks if a string starts with a specified substring.",
    codeString: 'str.startsWith("Hello")',
    exampleValue: '"Hello, World!"',
    exampleReturn: true,
  },
  {
    methodName: "charAt(index)",
    methodDesc: "Returns the character at the specified index.",
    codeString: "str.charAt(0)",
    exampleValue: '"Hello, World!"',
    exampleReturn: "H",
  },
  {
    methodName: "localeCompare(string)",
    methodDesc: "Compares two strings in the current locale.",
    codeString: "str1.localeCompare(str2)",
    exampleValue: 'let str1 = "apple" let str2 = "banana"',
    exampleReturn: -1,
  },
  {
    methodName: "indexOf(substring, [start])",
    methodDesc: "Returns the index of the first occurrence of a substring.",
    codeString: 'str.indexOf("World")',
    exampleValue: '"Hello, World!"',
    exampleReturn: 7,
  },
  {
    methodName: "lastIndexOf(substring, [start])",
    methodDesc: "Returns the index of the last occurrence of a substring.",
    codeString: 'str.lastIndexOf("o")',
    exampleValue: '"Hello, World!"',
    exampleReturn: 8,
  },
  {
    methodName: "replace(searchValue, replaceValue)",
    methodDesc:
      "Replaces occurrences of a specified substring or regular expression with another string.",
    codeString: 'str.replace("World", "Universe")',
    exampleValue: '"Hello, World!"',
    exampleReturn: "Hello, Universe!",
  },
  {
    methodName: "replaceAll(searchValue, replaceValue)",
    methodDesc:
      "Replaces all occurrences of a specified substring or regular expression with another string.",
    codeString: 'str.replaceAll("o", "0")',
    exampleValue: '"Hello, World!"',
    exampleReturn: "Hell0, W0rld!",
  },
  {
    methodName: "slice(start, [end])",
    methodDesc:
      "Extracts a section of a string and returns it as a new string.",
    codeString: "str.slice(7)",
    exampleValue: '"Hello, World!"',
    exampleReturn: "World!",
  },
  {
    methodName: "substring(start, [end])",
    methodDesc:
      "Extracts the characters between two specified indices and returns the new string.",
    codeString: "str.substring(7)",
    exampleValue: '"Hello, World!"',
    exampleReturn: "World!",
  },
  {
    methodName: "substr(start, [length])",
    methodDesc:
      "Extracts a specified number of characters from a string, starting at a specified index, and returns the new string.",
    codeString: "str.substr(7, 5)",
    exampleValue: '"Hello, World!"',
    exampleReturn: "World",
  },
  {
    methodName: "toLowerCase()",
    methodDesc: "Converts all characters in a string to lowercase.",
    codeString: "str.toLowerCase()",
    exampleValue: '"Hello, World!"',
    exampleReturn: "hello, world!",
  },
  {
    methodName: "toUpperCase()",
    methodDesc: "Converts all characters in a string to uppercase.",
    codeString: "str.toUpperCase()",
    exampleValue: '"Hello, World!"',
    exampleReturn: "HELLO, WORLD!",
  },
  {
    methodName: "trim()",
    methodDesc: "Removes whitespace from both ends of a string.",
    codeString: "str.trim()",
    exampleValue: '"   Hello, World!   "',
    exampleReturn: "Hello, World!",
  },
  {
    methodName: "concat(string1, [string2, ...])",
    methodDesc: "Concatenates two or more strings.",
    codeString: "str1.concat(str2)",
    exampleValue: `let str1 = "Hello"
                   let str2 = "World!"`,
    exampleReturn: "Hello, World!",
  },
];

// const DateMethods = [
//   {
//     methodName: "getDate()",
//     methodDesc:
//       "Returns the day of the month for the specified date according to local time.",
//     codeString: "new Date(date).getDate()",
//     exampleValue: "'2023-05-18'",
//     exampleReturn: 18,
//   },
//   {
//     methodName: "getDay()",
//     methodDesc:
//       "Returns the day of the week for the specified date according to local time.",
//     codeString: "new Date(date).getDay()",
//     exampleValue: "'2023-05-18'",
//     exampleReturn: 4,
//   },
//   {
//     methodName: "getFullYear()",
//     methodDesc:
//       "Returns the year of the specified date according to local time.",
//     codeString: "new Date(date).getFullYear()",
//     exampleValue: "'2023-05-18'",
//     exampleReturn: 2023,
//   },
//   {
//     methodName: "getMonth()",
//     methodDesc:
//       "Returns the month in the specified date according to local time, as a zero-based value (0 for January, 11 for December).",
//     codeString: "new Date(date).getMonth()",
//     exampleValue: "'2023-05-18'",
//     exampleReturn: 4,
//   },
//   {
//     methodName: "getHours()",
//     methodDesc:
//       "Returns the hour for the specified date according to local time.",
//     codeString: "new Date(date).getHours()",
//     exampleValue: "2023-05-18T13:24:00",
//     exampleReturn: 13,
//   },
//   {
//     methodName: "getMinutes()",
//     methodDesc:
//       "Returns the minutes in the specified date according to local time.",
//     codeString: "new Date(date).getMinutes()",
//     exampleValue: "2023-05-18T13:24:00",
//     exampleReturn: 24,
//   },
//   {
//     methodName: "getSeconds()",
//     methodDesc:
//       "Returns the seconds in the specified date according to local time.",
//     codeString: "new Date(date).getSeconds()",
//     exampleValue: "2023-05-18T13:24:00",
//     exampleReturn: 0,
//   },
//   {
//     methodName: "getTime()",
//     methodDesc:
//       "Returns the numeric value corresponding to the time for the specified date according to universal time.",
//     codeString: "new Date(date).getTime()",
//     exampleValue: "'2023-05-18'",
//     exampleReturn: 1684473600000,
//   },
//   {
//     methodName: "setDate(day)",
//     methodDesc:
//       "Sets the day of the month for a specified date according to local time.",
//     codeString: "new Date(date).setDate(20)",
//     exampleValue: "'2023-05-18'",
//     exampleReturn:
//       "Sat May 20 2023 00:00:00 GMT+0000 (Coordinated Universal Time)",
//   },
//   {
//     methodName: "setFullYear(year, [month, day])",
//     methodDesc:
//       "Sets the full year for a specified date according to local time.",
//     codeString: "new Date(date).setFullYear(2024)",
//     exampleValue: "'2023-05-18'",
//     exampleReturn:
//       "Sat May 18 2024 00:00:00 GMT+0000 (Coordinated Universal Time)",
//   },
//   {
//     methodName: "dayDiff(date1, date2)",
//     methodDesc: "Calculates the difference in days between two dates.",
//     codeString: "Math.floor((date2 - date1) / (1000 * 60 * 60 * 24))",
//     exampleValue: "let date1 = 2023-05-18; let date2 = new Date('2023-05-20')",
//     exampleReturn: 2,
//   },
//   {
//     methodName: "monthDiff(date1, date2)",
//     methodDesc: "Calculates the difference in months between two dates.",
//     codeString: `function monthDiff(date1, date2) {
//   return date2.getMonth() - date1.getMonth() +
//   (12 * (date2.getFullYear() - date1.getFullYear()));
// }`,
//     exampleValue: "let date1 = 2023-05-18; let date2 = new Date('2023-08-18');",
//     exampleReturn: 3,
//   },
//   {
//     methodName: "yearDiff(date1, date2)",
//     methodDesc: "Calculates the difference in years between two dates.",
//     codeString: "(date2.getFullYear() - date1.getFullYear())",
//     exampleValue: "let date1 = 2023-05-18; let date2 = new Date('2025-05-18');",
//     exampleReturn: 2,
//   },
// ];
const ArrayMethods = [
  {
    methodName: "push(element1, ..., elementN)",
    methodDesc:
      "Adds one or more elements to the end of an array and returns the new length of the array.",
    codeString: "arr.push(4, 5)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 5,
  },
  {
    methodName: "pop()",
    methodDesc:
      "Removes the last element from an array and returns that element.",
    codeString: "arr.pop()",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 3,
  },
  {
    methodName: "shift()",
    methodDesc:
      "Removes the first element from an array and returns that element.",
    codeString: "arr.shift()",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 1,
  },
  {
    methodName: "unshift(element1, ..., elementN)",
    methodDesc:
      "Adds one or more elements to the beginning of an array and returns the new length of the array.",
    codeString: "arr.unshift(0)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 4,
  },
  {
    methodName: "map(callback)",
    methodDesc:
      "Creates a new array populated with the results of calling a provided function on every element in the calling array.",
    codeString: "arr.map(x => x * 2)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: "[2, 4, 6]",
  },
  {
    methodName: "filter(callback)",
    methodDesc:
      "Creates a new array with all elements that pass the test implemented by the provided function.",
    codeString: "arr.filter(x => x > 1)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: "[2, 3]",
  },
  {
    methodName: "reduce(callback, [initialValue])",
    methodDesc:
      "Executes a reducer function on each element of the array, resulting in a single output value.",
    codeString: "arr.reduce((acc, x) => acc + x, 0)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 6,
  },
  {
    methodName: "forEach(callback)",
    methodDesc: "Executes a provided function once for each array element.",
    codeString: "arr.forEach(x => console.log(x))",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: "1\n2\n3",
  },
  {
    methodName: "find(callback)",
    methodDesc:
      "Returns the value of the first element in the array that satisfies the provided testing function.",
    codeString: "arr.find(x => x > 1)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 2,
  },
  {
    methodName: "indexOf(searchElement, [fromIndex])",
    methodDesc:
      "Returns the first index at which a given element can be found in the array, or -1 if it is not present.",
    codeString: "arr.indexOf(2)",
    exampleValue: "let arr = [1, 2, 3]",
    exampleReturn: 1,
  },
];
const NumericMethods = [
  {
    methodName: "toFixed(digits)",
    methodDesc: "Formats a number using fixed-point notation.",
    codeString: "num.toFixed(2)",
    exampleValue: "123.456",
    exampleReturn: "123.46",
  },
  {
    methodName: "toString([radix])",
    methodDesc:
      "Returns a string representing the specified number in the specified radix (base).",
    codeString: "num.toString(2)",
    exampleValue: "10",
    exampleReturn: "1010",
  },
  {
    methodName: "toExponential(digits)",
    methodDesc:
      "Returns a string representing the number in exponential notation.",
    codeString: "num.toExponential(2)",
    exampleValue: "123.456",
    exampleReturn: "1.23e+2",
  },
  {
    methodName: "toPrecision(digits)",
    methodDesc:
      "Returns a string representing the number to the specified precision.",
    codeString: "num.toPrecision(4)",
    exampleValue: "123.456",
    exampleReturn: "123.5",
  },
  {
    methodName: "valueOf()",
    methodDesc: "Returns the primitive value of a number object.",
    codeString: "num.valueOf()",
    exampleValue: "new Number(123)",
    exampleReturn: 123,
  },
];
export const BooleanMethods = [
  {
    methodName: "toString()",
    methodDesc: "Returns a string representing the specified Boolean object.",
    codeString: "bool.toString()",
    exampleValue: "true;",
    exampleReturn: "true",
  },
];
const SUM = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM(1, 2, 3, 4) > 5",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM(1, 2, 3, 4) === 10",
    exampleValue: "",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM(1, 2, 3, 4)10",
    exampleValue: "",
    exampleReturn: "10",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM(5, 10, 15)30",
    exampleValue: "",
    exampleReturn: "30",
  },
];
const SUM_LIST = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM_LIST(list={{.customInput.Loan_amount}}) > 10000",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn:
      "true (if the sum of the loan amounts is greater than 10000)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM_LIST(list={{.customInput.Scores}}, 'value') === 50",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn:
      "true (if the sum of the 'value' key in the objects of the list is 50)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM_LIST(list=[1,2,3,4])",
    exampleValue: "[1,2,3,4]",
    exampleReturn: "10",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "SUM_LIST(list={{.customInput.Scores}}, 'value')",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn: "sum of the 'value' key in the objects of the list",
  },
];
const AVG = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG(1, 2, 3, 4) > 2",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG(1, 2, 3, 4) === 2.5",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG(1, 2, 3, 4)",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "2.5",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG(5, 10, 15)10",
    exampleValue: "",
    exampleReturn: "",
  },
];
const AVG_LIST = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG_LIST(list={{.customInput.Loan_amount}}) > 5000",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn:
      "true (if the average of the loan amounts is greater than 5000)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG_LIST(list={{.customInput.Scores}}, 'value') === 25",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn:
      "true (if the average of the 'value' key in the objects of the list is 25)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG_LIST(list={{.customInput.Loan_amount}})",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn: "average of the loan amounts",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "AVG_LIST(list={{.customInput.Scores}}, 'value')",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn: "average of the 'value' key in the objects of the list",
  },
];
const MIN = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN(1, 2, 3, 4) < 2",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN(1, 2, 3, 4) === 1",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN(1, 2, 3, 4)",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN(5, 10, 15)",
    exampleValue: "5, 10, 15",
    exampleReturn: "5",
  },
];
const MIN_LIST = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN_LIST(list={{.customInput.Loan_amount}}) < 1000",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn: "true (if the minimum loan amount is less than 1000)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN_LIST(list={{.customInput.Scores}}, 'value') === 10 ",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn:
      "true (if the minimum 'value' key in the objects of the list is 10)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN_LIST(list={{.customInput.Loan_amount}})",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn: "minimum loan amount",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MIN_LIST(list={{.customInput.Scores}}, 'value')",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn: "minimum 'value' key in the objects of the list",
  },
];
const MAX = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX(1, 2, 3, 4) > 3",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX(1, 2, 3, 4) === 4",
    exampleValue: "1, 2, 3, 4",
    exampleReturn: "true",
  },
];
const MAX_LIST = [
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX_LIST(list={{.customInput.Loan_amount}}) > 10000",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn: "true (if the maximum loan amount is greater than 10000)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX_LIST(list={{.customInput.Scores}}, 'value') === 50",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn:
      "true (if the maximum 'value' key in the objects of the list is 50)",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX_LIST(list={{.customInput.Loan_amount}})",
    exampleValue: "{{.customInput.Loan_amount}}",
    exampleReturn: "maximum loan amount",
  },
  {
    methodName: "Example",
    methodDesc: "",
    codeString: "MAX_LIST(list={{.customInput.Scores}}, 'value')",
    exampleValue: "{{.customInput.Scores}}",
    exampleReturn: "maximum 'value' key in the objects of the list",
  },
];
const DAY = [
  {
    methodName: "Example",
    methodDesc: "Returns the day of the month from a given date.",
    codeString: "DAY('2024-06-28')",
    exampleValue: "'2024-06-28'",
    exampleReturn: "28",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the day of the month from a given date.",
    codeString: "DAY('2023-01-01')",
    exampleValue: "'2023-01-01'",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the day of the month from a given date.",
    codeString: "DAY('2022-12-31')",
    exampleValue: "'2022-12-31'",
    exampleReturn: "31",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the day of the month from a given date.",
    codeString: 'DAY("{{.customInput.date}}") // token value : 2022-12-31',
    exampleValue: "'2022-12-31'",
    exampleReturn: "31",
  },
];
const MONTH = [
  {
    methodName: "Example",
    methodDesc: "Returns the month from a given date.",
    codeString: "MONTH('2024-06-28')",
    exampleValue: "'2024-06-28'",
    exampleReturn: "6",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the month from a given date.",
    codeString: "MONTH('2023-01-01')",
    exampleValue: "'2023-01-01'",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the month from a given date.",
    codeString: "MONTH('2022-12-31')",
    exampleValue: "'2022-12-31'",
    exampleReturn: "12",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the month from a given date.",
    codeString: 'MONTH("{{.customInput.date}}") // token value : 2022-12-31',
    exampleValue: "'2022-12-31'",
    exampleReturn: "12",
  },
];
const YEAR = [
  {
    methodName: "Example",
    methodDesc: "Returns the year from a given date.",
    codeString: "YEAR('2024-06-28')",
    exampleValue: "'2024-06-28'",
    exampleReturn: "2024",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the year from a given date.",
    codeString: "YEAR('2023-01-01')",
    exampleValue: "'2023-01-01'",
    exampleReturn: "2023",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the year from a given date.",
    codeString: "YEAR('2022-12-31')",
    exampleValue: "'2022-12-31'",
    exampleReturn: "2022",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the year from a given date.",
    codeString: 'YEAR("{{.customInput.date}}") // token value : 2022-12-31',
    exampleValue: "'2022-12-31'",
    exampleReturn: "2022",
  },
];
const MINUTE = [
  {
    methodName: "Example",
    methodDesc: "Returns the minute component of a given datetime.",
    codeString: "MINUTE('2024-06-28T14:30:45Z')",
    exampleValue: "'2024-06-28T14:30:45Z'",
    exampleReturn: "30",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the minute component of a given datetime.",
    codeString: "MINUTE('2023-01-01T00:59:01Z')",
    exampleValue: "'2023-01-01T00:59:01Z'",
    exampleReturn: "59",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the minute component of a given datetime.",
    codeString: "MINUTE('2022-12-31T23:00:59Z')",
    exampleValue: "'2022-12-31T23:00:59Z'",
    exampleReturn: "0",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the minute component of a given datetime.",
    codeString:
      'MINUTE("{{.customInput.datetime}}") // token value : 2022-12-31T23:00:59Z',
    exampleValue: "'2022-12-31T23:00:59Z'",
    exampleReturn: "23",
  },
];
const HOUR = [
  {
    methodName: "Example",
    methodDesc: "Returns the hour component of a given datetime.",
    codeString: "HOUR('2024-06-28T14:30:45Z')",
    exampleValue: "'2024-06-28T14:30:45Z'",
    exampleReturn: "14",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the hour component of a given datetime.",
    codeString: "HOUR('2023-01-01 23:59:01')",
    exampleValue: "'2023-01-01 23:59:01'",
    exampleReturn: "23",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the hour component of a given datetime.",
    codeString: "HOUR('2022-12-31T00:00:59Z')",
    exampleValue: "'2022-12-31T00:00:59Z'",
    exampleReturn: "0",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the hour component of a given datetime.",
    codeString:
      'HOUR("{{.customInput.datetime}}") // token value : 2022-12-31T23:00:59Z',
    exampleValue: "'2022-12-31T00:00:59Z'",
    exampleReturn: "0",
  },
];
const SECOND = [
  {
    methodName: "Example",
    methodDesc: "Returns the second component of a given datetime.",
    codeString: "SECOND('2024-06-28T14:30:45Z')",
    exampleValue: "'2024-06-28T14:30:45Z'",
    exampleReturn: "45",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the second component of a given datetime.",
    codeString: "SECOND('2023-01-01T00:00:01Z')",
    exampleValue: "'2023-01-01T00:00:01Z'",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the second component of a given datetime.",
    codeString: "SECOND('2022-12-31T23:59:59Z')",
    exampleValue: "'2022-12-31T23:59:59Z'",
    exampleReturn: "59",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the second component of a given datetime.",
    codeString:
      'MINUTE("{{.customInput.datetime}}") // token value : 2022-12-31T23:00:59Z',
    exampleValue: "'2022-12-31T23:59:59Z'",
    exampleReturn: "59",
  },
];
const WEEK = [
  {
    methodName: "Example",
    methodDesc: "Returns the week number of a given date.",
    codeString: "WEEK('2024-06-28')",
    exampleValue: "'2024-06-28'",
    exampleReturn: "4",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the week number of a given date.",
    codeString: "WEEK('2023-01-01')",
    exampleValue: "'2023-01-01'",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the week number of a given date.",
    codeString: 'WEEK("{{.customInput.date}}") // token value : 2022-12-31',
    exampleValue: "'2022-12-31'",
    exampleReturn: "5",
  },
];
const NOW = [
  {
    methodName: "Example",
    methodDesc: "Returns the current date and time in current locale",
    codeString: "NOW()",
    exampleValue: "",
    exampleReturn: `${dateFormat(new Date(), "yyyy-mm-dd'T'HH:mm:ss")}`,
  },
  {
    methodName: "Example",
    methodDesc: "Returns the current date and time in specified timezone",
    codeString: `NOW('${Intl.DateTimeFormat().resolvedOptions().timeZone}')`,
    exampleValue: "",
    exampleReturn: `${dateFormat(new Date(), "yyyy-mm-dd'T'HH:mm:ss")}`,
  },
];
const TODAY = [
  {
    methodName: "Example",
    methodDesc: "Returns the current date in current locale",
    codeString: "TODAY()",
    exampleValue: "",
    exampleReturn: `${dateFormat(new Date(), "yyyy-mm-dd")}`,
  },
  {
    methodName: "Example",
    methodDesc: "Returns the current date in specified timezone",
    codeString: `TODAY('${Intl.DateTimeFormat().resolvedOptions().timeZone}')`,
    exampleValue: "",
    exampleReturn: `${dateFormat(new Date(), "yyyy-mm-dd")}`,
  },
];
const DATEDIFF = [
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in days between two specified dates.",
    codeString: "DATEDIFF('2024-06-25', '2024-06-20', 'day')",
    exampleValue: "'2024-06-25', '2024-06-20', 'day'",
    exampleReturn: "5",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in days between two specified dates.",
    codeString:
      'DATEDIFF("{{.customInput.date1}}", "{{.customInput.date1}}", "day")',
    exampleValue: "'2024-06-25', '2024-06-20', 'day'",
    exampleReturn: "5",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in months between two specified dates.",
    codeString: "DATEDIFF('2024-06-25', '2024-01-01', 'month')",
    exampleValue: "'2024-06-25', '2024-01-01', 'month'",
    exampleReturn: "5",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in year between two specified dates.",
    codeString: "DATEDIFF('2024-06-25', '2023-01-01', 'year')",
    exampleValue: "'2024-06-25', '2023-01-01', 'year'",
    exampleReturn: "1",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in hours between two specified timestamps.",
    codeString:
      "DATEDIFF('2024-06-25T12:00:00Z', '2024-06-25T10:00:00Z', 'hour')",
    exampleValue: "'2024-06-25T12:00:00Z', '2024-06-25T10:00:00Z', 'hour'",
    exampleReturn: "2",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in weeks between two specified dates.",
    codeString: "DATEDIFF('30-06-2024', '15-06-2024', 'week')",
    exampleValue: "'30-06-2024', '15-06-2024', 'week'",
    exampleReturn: "2",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in minutes between two specified timestamps.",
    codeString:
      "DATEDIFF('2024-06-25T12:30:00Z', '2024-06-25T12:00:00Z', 'minute')",
    exampleValue: "'2024-06-25T12:30:00Z', '2024-06-25T12:00:00Z', 'minute'",
    exampleReturn: "30",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the difference in seconds between two specified timestamps.",
    codeString:
      "DATEDIFF('2024-06-25T12:00:30Z', '2024-06-25T12:00:00Z', 'second')",
    exampleValue: "'2024-06-25T12:00:30Z', '2024-06-25T12:00:00Z', 'second'",
    exampleReturn: "30",
  },
];
const DATECOMPUTE = [
  {
    methodName: "Example",
    methodDesc: "Computes a new date by adding 2 days from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '+2', 'day')",
    exampleValue: "'2024-06-25', '+2', 'day'",
    exampleReturn: "2024-06-27",
  },
  {
    methodName: "Example",
    methodDesc: "Computes a new date by adding 2 days from June 25, 2024.",
    codeString: 'DATECOMPUTE("{{.customInput.date1}}", "+2", "day")',
    exampleValue: "'2024-06-25', '+2', 'day'",
    exampleReturn: "2024-06-27",
  },
  {
    methodName: "Example",
    methodDesc: "Computes a new date by subtracting 5 days from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '-5', 'day')",
    exampleValue: "'2024-06-25', '-5', 'day'",
    exampleReturn: "2024-06-20",
  },
  {
    methodName: "Example",
    methodDesc: "Computes a new date by adding 3 months from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '+3', 'month')",
    exampleValue: "'2024-06-25', '+3', 'month'",
    exampleReturn: "2024-09-25",
  },
  {
    methodName: "Example",
    methodDesc:
      "Computes a new date by subtracting 2 month from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '-2', 'month')",
    exampleValue: "'2024-06-25', '-2', 'month'",
    exampleReturn: "2024-04-25",
  },
  {
    methodName: "Example",
    methodDesc: "Computes a new date by adding 1 year from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '+1', 'year')",
    exampleValue: "'2024-06-25', '+1', 'year'",
    exampleReturn: "2025-06-25",
  },
  {
    methodName: "Example",
    methodDesc: "Computes a new date by subtracting 5years from June 25, 2024.",
    codeString: "DATECOMPUTE('2024-06-25', '-5', 'year')",
    exampleValue: "'2024-06-25', '-5', 'year'",
    exampleReturn: "2019-06-25",
  },
  {
    methodName: "Example",
    methodDesc:
      "Computes a new date by adding 6 hours from June 25, 2024, 12:00:00 PM.",
    codeString: "DATECOMPUTE('2024-06-25T12:00:00', '+6', 'hour')",
    exampleValue: "'2024-06-25T12:00:00', '+6', 'hour'",
    exampleReturn: "2024-06-25T18:00:00",
  },
  {
    methodName: "Example",
    methodDesc:
      "Computes a new date by subtracting 3 hours from June 25, 2024, 12:00:00 PM.",
    codeString: "DATECOMPUTE('2024-06-25T12:00:00', '-3', 'hour')",
    exampleValue: "'2024-06-25T12:00:00', '-3', 'hour'",
    exampleReturn: "2024-06-25T09:00:00",
  },
];
const DATEFORMAT = [
  {
    methodName: "DATEFORMAT",
    methodDesc:
      "Formats a date to the default format 'ddd mmm dd yyyy HH:MM:ss'.",
    codeString:
      "DATEFORMAT({{.customInput.dateTimeToken}}, 'ddd mmm dd yyyy HH:MM:ss')",
    exampleValue: "'2007-06-09T17:46:21', 'ddd mmm dd yyyy HH:MM:ss'",
    exampleReturn: "Sat Jun 09 2007 17:46:21",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc:
      "Formats a date to the default format 'ddd mmm dd yyyy HH:MM:ss'.",
    codeString: "DATEFORMAT('2007-06-09 17:46:21', 'ddd mmm dd yyyy HH:MM:ss')",
    exampleValue: "'2007-06-09 17:46:21', 'ddd mmm dd yyyy HH:MM:ss'",
    exampleReturn: "Sat Jun 09 2007 17:46:21",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the short date format 'm/d/yy'.",
    codeString: "DATEFORMAT('2007-06-09', 'm/d/yy')",
    exampleValue: "'2007-06-09', 'm/d/yy'",
    exampleReturn: "6/9/07",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the padded short date format 'mm/dd/yyyy'.",
    codeString: "DATEFORMAT('2007-06-09', 'mm/dd/yyyy')",
    exampleValue: "'2007-06-09', 'mm/dd/yyyy'",
    exampleReturn: "06/09/2007",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the medium date format 'mmm d, yyyy'.",
    codeString: "DATEFORMAT('2007-06-09', 'mmm d, yyyy')",
    exampleValue: "'2007-06-09', 'mmm d, yyyy'",
    exampleReturn: "Jun 9, 2007",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the long date format 'mmmm d, yyyy'.",
    codeString: "DATEFORMAT('2007-06-09', 'mmmm d, yyyy')",
    exampleValue: "'2007-06-09', 'mmmm d, yyyy'",
    exampleReturn: "June 9, 2007",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the full date format 'dddd, mmmm d, yyyy'.",
    codeString: "DATEFORMAT('2007-06-09', 'dddd, mmmm d, yyyy')",
    exampleValue: "'2007-06-09', 'dddd, mmmm d, yyyy'",
    exampleReturn: "Saturday, June 9, 2007",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats time to the short time format 'h:MM TT'.",
    codeString: "DATEFORMAT('2007-06-09 17:46:21', 'h:MM TT')",
    exampleValue: "'2007-06-09 17:46:21', 'h:MM TT'",
    exampleReturn: "5:46 PM",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats time to the medium time format 'H:MM:ss TT'.",
    codeString: "DATEFORMAT('2007-06-09 17:46:21', 'H:MM:ss TT')",
    exampleValue: "'2007-06-09 17:46:21', 'H:MM:ss TT'",
    exampleReturn: "5:46:21 PM",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats a date to the ISO date format 'yyyy-mm-dd'.",
    codeString: "DATEFORMAT('2007-06-09', 'yyyy-mm-dd')",
    exampleValue: "'2007-06-09', 'yyyy-mm-dd'",
    exampleReturn: "2007-06-09",
  },
  {
    methodName: "DATEFORMAT",
    methodDesc: "Formats time to the ISO time format 'HH:MM:ss'.",
    codeString: "DATEFORMAT('2007-06-09 17:46:21', 'HH:MM:ss')",
    exampleValue: "'2007-06-09 17:46:21', 'HH:MM:ss'",
    exampleReturn: "17:46:21",
  },
];
const DATEPARSE = [
  {
    methodName: "DATEPARSE",
    methodDesc: "Returns date/datetime object in RFC3339",
    codeString: "DATEPARSE({{.customInput.dateTimeToken}}, 'mm-dd-yyyy')",
    exampleValue: "'2007-06-09', 'm/d/yy'",
    exampleReturn: "2024-06-12T00:00:00Z",
  },
  {
    methodName: "DATEPARSE",
    methodDesc: "Returns date/datetime object in RFC3339",
    codeString: "DATEPARSE('2007-06-09 17:46:21', 'yyyy-mm-dd HH:MM:ss')",
    exampleValue: "'2007-06-09T17:46:21', 'yyyy-mm-dd HH:MM:ss'",
    exampleReturn: "2010-10-09T17:00:21Z",
  },
  {
    methodName: "DATEPARSE",
    methodDesc: "Returns date/datetime object in RFC3339",
    codeString: "DATEPARSE('06-12-2024', 'mm-dd-yyyy')",
    exampleValue: "'2007-06-09', 'm/d/yy'",
    exampleReturn: "2024-06-12T00:00:00Z",
  },
];

const POW = [
  {
    methodName: "Example",
    methodDesc: "Raises a number to the power of another number.",
    codeString: "POW(5, 2)",
    exampleValue: "5, 2",
    exampleReturn: "25",
  },
  {
    methodName: "Example",
    methodDesc: "Raises a number to the power of another number.",
    codeString: "POW(10, 0)",
    exampleValue: "10, 0",
    exampleReturn: "1",
  },
];
const SQRT = [
  {
    methodName: "Example",
    methodDesc: "Returns the square root of a number.",
    codeString: "SQRT(25)",
    exampleValue: "25",
    exampleReturn: "5",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the square root of a number.",
    codeString: "SQRT(9)",
    exampleValue: "9",
    exampleReturn: "3",
  },
  {
    methodName: "Example",
    methodDesc: "Returns the square root of a number.",
    codeString: 'SQRT("{{.customInput.number}}")',
    exampleValue: "9",
    exampleReturn: "3",
  },
];
const PMT = [
  {
    methodName: "Example",
    methodDesc:
      "Calculates the payment for a loan of $5000 at an interest rate of 4% per annum, compounded monthly, with a tenure of 36 months.",
    codeString: "PMT(0.04/12, 36, 5000)",
    exampleValue: "0.04/12, 36, 5000",
    exampleReturn: "-147.61992503421476",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the payment for a loan of $2000 at an interest rate of 3% per annum, compounded monthly, with a tenure of 12 months.",
    codeString: "PMT(0.03/12, 12, 2000)",
    exampleValue: "0.03/12, 12, 2000",
    exampleReturn: "-169.38739751698108",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the payment for a loan of $10,000 at an interest rate of 5% per annum, compounded monthly, with a tenure of 60 months.",
    codeString: "PMT(0.05/12, 60, 10000)",
    exampleValue: "0.05/12, 60, 10000",
    exampleReturn: "-188.71233644010874",
  },
];
const PV = [
  {
    methodName: "Example",
    methodDesc:
      "Calculates the present value of an investment that generates a series of future payments of $188.71 per month, at an interest rate of 5% per annum, compounded monthly, for a period of 60 months.",
    codeString: "PV(0.05/12, 60, -188.71)",
    exampleValue: "0.05/12, 60, -188.71",
    exampleReturn: "9999.876190388355",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the present value of an investment that generates a series of future payments of $147.22 per month, at an interest rate of 4% per annum, compounded monthly, for a period of 36 months.",
    codeString: "PV(0.04/12, 36, -147.22)",
    exampleValue: "0.04/12, 36, -147.22",
    exampleReturn: "4986.454232579983",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the present value of an investment that generates a series of future payments of $170.92 per month, at an interest rate of 3% per annum, compounded monthly, for a period of 12 months.",
    codeString: "PV(0.03/12, 12, -170.92)",
    exampleValue: "0.03/12, 12, -170.92",
    exampleReturn: "2018.0958265548095",
  },
];
const EMI = [
  {
    methodName: "Example",
    methodDesc:
      "Calculates the Equated Monthly Installment (EMI) for a loan of $5000 at an interest rate of 4% per annum, compounded monthly, for a tenure of 36 months.",
    codeString: "EMI(0.04/12, 36,5000)",
    exampleValue: "0.04/12, 36,5000",
    exampleReturn: "147.22",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the Equated Monthly Installment (EMI) for a loan of $2000 at an interest rate of 3% per annum, compounded monthly, for a tenure of 12 months.",
    codeString: "EMI(0.03/12, 12, 2000)",
    exampleValue: "0.03/12, 12, 2000",
    exampleReturn: "169.39",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the Equated Monthly Installment (EMI) for a loan of $10,000 at an interest rate of 5% per annum, compounded monthly, with a tenure of 60 months, and no initial principal (pv) and future value (fv).",
    codeString: "EMI(0.05/12, 60 , 10000, 0, 0)",
    exampleValue: "0.05/12, 60, 10000, 0, 0",
    exampleReturn: "188.71",
  },
  {
    methodName: "Example",
    methodDesc:
      "Calculates the Equated Monthly Installment (EMI) for a loan of $10,000 at an interest rate of 5% per annum, compounded monthly, with a tenure of 60 months, no initial principal (pv), and a future value (fv) of $500.",
    codeString: "EMI(0.05/12, 60, 10000 , 0, 500)",
    exampleValue: "0.05/12, 60, 10000, 0, 500",
    exampleReturn: "189.50",
  },
];
const LIMIT = [
  {
    methodName: "Example",
    methodDesc:
      "Limits the number of elements returned in an array to a specified number.",
    codeString: "LIMIT([1, 2, 3, 4, 5], 3)",
    exampleValue: "[1, 2, 3, 4, 5], 3",
    exampleReturn: "[1, 2, 3]",
  },
  {
    methodName: "Example",
    methodDesc:
      "Limits the number of elements returned in an array to a specified number.",
    codeString:
      "LIMIT([ { id: 1, name: 'John' }, { id: 2, name: 'Jane' }, { id: 3, name: 'Doe' } ], 2)",
    exampleValue:
      "[ { id: 1, name: 'John' }, { id: 2, name: 'Jane' }, { id: 3, name: 'Doe' } ], 2",
    exampleReturn: "[ { id: 1, name: 'John' }, { id: 2, name: 'Jane' } ]",
  },
];
const SORT = [
  {
    methodName: "Example",
    methodDesc: "Sorts a list of numbers in ascending order.",
    codeString: "SORT([5, 3, 8, 1, 2], 'ASC')",
    exampleValue: "[5, 3, 8, 1, 2], 'ASC'",
    exampleReturn: "[1, 2, 3, 5, 8]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of numbers in ascending order.",
    codeString: "SORT({{.customInput.list}}, 'ASC')",
    exampleValue: "[5, 3, 8, 1, 2], 'ASC'",
    exampleReturn: "[1, 2, 3, 5, 8]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of numbers in descending order.",
    codeString: "SORT([5, 3, 8, 1, 2], 'DESC')",
    exampleValue: "[5, 3, 8, 1, 2], 'DESC'",
    exampleReturn: "[8, 5, 3, 2, 1]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of objects by a single key in ascending order.",
    codeString:
      "SORT([{ id: 3, name: 'John' }, { id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }], 'ASC', 'id')",
    exampleValue:
      "[{ id: 3, name: 'John' }, { id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }], 'ASC', 'id'",
    exampleReturn:
      "[{ id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }, { id: 3, name: 'John' }]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of objects by a single key in descending order.",
    codeString:
      "SORT([{ id: 3, name: 'John' }, { id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }], 'DESC', 'id')",
    exampleValue:
      "[{ id: 3, name: 'John' }, { id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }], 'DESC', 'id'",
    exampleReturn:
      "[{ id: 3, name: 'John' }, { id: 2, name: 'Doe' }, { id: 1, name: 'Jane' }]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of objects by a single key in descending order.",
    codeString: "SORT({{.customInput.listObjs}}, 'DESC', 'id')",
    exampleValue:
      "[{ id: 3, name: 'John' }, { id: 1, name: 'Jane' }, { id: 2, name: 'Doe' }], 'DESC', 'id'",
    exampleReturn:
      "[{ id: 3, name: 'John' }, { id: 2, name: 'Doe' }, { id: 1, name: 'Jane' }]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of objects by multiple keys in ascending order.",
    codeString:
      "SORT([{ id: 3, name: 'John', age: 30 }, { id: 1, name: 'Jane', age: 25 }, { id: 2, name: 'Doe', age: 28 }], 'ASC')",
    exampleValue:
      "[{ id: 3, name: 'John', age: 30 }, { id: 1, name: 'Jane', age: 25 }, { id: 2, name: 'Doe', age: 28 }], 'ASC'",
    exampleReturn:
      "[{ id: 1, name: 'Jane', age: 25 }, { id: 2, name: 'Doe', age: 28 }, { id: 3, name: 'John', age: 30 }]",
  },
  {
    methodName: "Example",
    methodDesc: "Sorts a list of objects by multiple keys in descending order.",
    codeString:
      "SORT([{ id: 3, name: 'John', age: 30 }, { id: 1, name: 'Jane', age: 25 }, { id: 2, name: 'Doe', age: 28 }], 'DESC')",
    exampleValue:
      "[{ id: 3, name: 'John', age: 30 }, { id: 1, name: 'Jane', age: 25 }, { id: 2, name: 'Doe', age: 28 }], 'DESC'",
    exampleReturn:
      "[{ id: 3, name: 'John', age: 30 }, { id: 2, name: 'Doe', age: 28 }, { id: 1, name: 'Jane', age: 25 }]",
  },
];
const COUNT = [
  {
    methodName: "Example",
    methodDesc: "Returns the length of the list. In this case which is a token",
    codeString: `COUNT({{.customInput.listObjs}})`,
    exampleValue: [
      { k1: 1, k2: "s" },
      { k1: 2, k2: "b" },
      { k1: 4, k2: "apple" },
    ],
    exampleReturn: "3",
  },
];
const FILTER = [
  {
    methodName: "Example",
    methodDesc:
      "Filter objects where k1 is less than or equal to the sum of 1 and 3, k2 length is greater than 0, the first character of k2 is not 'p', and k1 is greater than 1.",
    codeString: `FILTER({{.customInput.listObjs}}, "$item.$k1 <= (1 + 3) && $item.$k2.length > 0 && $item.$k2[0] != 'p' && $item.$k1 > 1")`,
    exampleValue: [
      { k1: 1, k2: "s" },
      { k1: 2, k2: "b" },
      { k1: 4, k2: "apple" },
    ],
    exampleReturn: '[{k1: 2, k2: "b"}]',
  },
  {
    methodName: "Example",
    methodDesc:
      "Filter objects where k1 is less than or equal to the sum of 1 and 3, k2 length is greater than 0, the first character of k2 is not 'p', and k1 is greater than 1.",
    codeString: `FILTER([{k1: 1, k2: "s"}, {k1: 2, k2: "b"}, {k1: 4, k2: "apple"}], "$item.$k1 <= (1 + 3) && $item.$k2.length > 0 && $item.$k2.indexOf('p') > -1 && $item.$k1 > 1")`,
    exampleValue: [
      { k1: 1, k2: "s" },
      { k1: 2, k2: "b" },
      { k1: 4, k2: "apple" },
    ],
    exampleReturn: '[{k1: 2, k2: "b"}]',
  },
  {
    methodName: "Example",
    methodDesc: "Filter objects where k1 is greater than 1 and only return k1.",
    codeString: `FILTER([{k1: 1, k2: "s"}, {k1: 2, k2: "b"}, {k1: 4, k2: "d"}], "$item.$k1 > 1", "k1")`,
    exampleValue: [
      { k1: 1, k2: "s" },
      { k1: 2, k2: "b" },
      { k1: 4, k2: "d" },
    ],
    exampleReturn: "[{k1: 2}, {k1: 4}]",
  },
  {
    methodName: "Example",
    methodDesc: "Filter objects where k2 length is 1 and only return k2.",
    codeString: `FILTER([{k1: 1, k2: "s"}, {k1: 2, k2: "bb"}, {k1: 4, k2: "d"}], "$item.$k2.length === 1")`,
    exampleValue: [
      { k1: 1, k2: "s" },
      { k1: 2, k2: "bb" },
      { k1: 4, k2: "d" },
    ],
    exampleReturn: '[{k1: 1, k2: "s"}, {k1: 4, k2: "d"}]',
  },
];
const DISTINCT = [
  {
    methodName: "Example",
    methodDesc:
      "Removes all the duplicate items and object and return the array/list",
    codeString: `DISTINCT({{.customInput.listObjs}}) [{k1: 2, k2: "s", k3 : 4}, {k1: 2, k2: "s" , k3 : 5}, {k1: 1, k2: "a"}]`,
    exampleValue: [
      { k1: 2, k2: "s", k3: 4 },
      { k1: 2, k2: "s", k3: 5 },
      { k1: 1, k2: "a" },
    ],
    exampleReturn:
      '[{k1: 2, k2: "s", k3 : 4}, {k1: 2, k2: "s" , k3 : 5}, {k1: 1, k2: "a"}]',
  },
  {
    methodName: "Example",
    methodDesc:
      "Removes all the duplicate items and object and return the array/list",
    codeString: `DISTINCT({{.customInput.listObjs}}) //[{k1: 2, k2: "s", k3 : 4}, {k1: 2, k2: "s" , k3 : 4}, {k1: 1, k2: "a"}]`,
    exampleValue: [
      { k1: 2, k2: "s", k3: 4 },
      { k1: 2, k2: "s", k3: 4 },
      { k1: 1, k2: "a" },
    ],
    exampleReturn: '[{k1: 2, k2: "s", k3 : 4}, {k1: 1, k2: "a"}]',
  },
];

export const MethodsMap = {
  "Array/List": ArrayMethods,
  "AVG()": AVG,
  "AVG_LIST()": AVG_LIST,
  BOOLEAN: BooleanMethods,
  "COUNT()": COUNT,
  "DATECOMPUTE()": DATECOMPUTE,
  "DATEFORMAT()": DATEFORMAT,
  "DATEPARSE()": DATEPARSE,
  "DATEDIFF()": DATEDIFF,
  "DAY()": DAY,
  "EMI()": EMI,
  "HOUR()": HOUR,
  "MAX()": MAX,
  "MAX_LIST()": MAX_LIST,
  "MIN()": MIN,
  "MIN_LIST()": MIN_LIST,
  "MINUTE()": MINUTE,
  "MONTH()": MONTH,
  "NOW()": NOW,
  NUMERIC: NumericMethods,
  "POW()": POW,
  "PMT()": PMT,
  "PV()": PV,
  "SECOND()": SECOND,
  "SQRT()": SQRT,
  String: StringMethods,
  "SUM()": SUM,
  "SUM_LIST()": SUM_LIST,
  "TODAY()": TODAY,
  "WEEK()": WEEK,
  "YEAR()": YEAR,
  "LIMIT()": LIMIT,
  "SORT()": SORT,
  "FILTER()": FILTER,
  "DISTINCT()": DISTINCT,
};

export const ExampleString = {
  String: "let str = ",
  Date: "let date = ",
  Numeric: "let num = ",
  "Array/List": "let list = ",
  Boolean: "let bool = ",
};

export const NOCODE_DEFAULT_VALUE = {
  string: "",
  number: 0,
  boolean: true,
  object: {},
  array: [],
  date: dateFormat(new Date(), "yyyy-mm-dd"),
  dateTime: dateFormat(new Date(), "isoUtcDateTime"),
  null: null,
};

export const NoCodeDataTypes = [
  {
    name: "String",
    value: "string",
  },
  {
    name: "Number",
    value: "number",
  },
  {
    name: "Boolean",
    value: "boolean",
  },
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Date Time",
    value: "dateTime",
  },
  {
    name: "JSON",
    value: "object",
  },
  {
    name: "List",
    value: "array",
  },
];

export const testJSON = (text) => {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    try {
      if (typeof text === "object") {
        JSON.stringify(text);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }
};

const TIMEZONES = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const timezoneSuggestions = TIMEZONES.map((timezone) => ({
  name: timezone,
  value: `"${timezone}"`,
  meta: "timezone",
  score: 999,
}));

const DATE_COMPUTE_UNITS = ["Day", "Month", "Year", "Hour", "Minute", "Second"];

export const dateComputeSuggestions = DATE_COMPUTE_UNITS.map((i) => ({
  name: i,
  value: `"${i}"`,
  meta: "units",
  score: 999,
}));

export const replaceRCNameWithID = (value, globalConnectors) => {
  let updatedValue = value;
  // Iterate through the connectors array and replace connectorName in the string
  if (globalConnectors && globalConnectors.length > 0) {
    globalConnectors.forEach(function (connector) {
      var connectorName = connector.name;
      var connectorValue = connector.value;
      var regex = new RegExp("connectorName='" + connectorName + "'", "g");
      updatedValue = updatedValue.replace(
        regex,
        "NEC_EXEC_ENV,connectorId='" + connectorValue + "'"
      );
    });
  }
  return updatedValue;
};

export const replaceRCIDWithName = (value, globalConnectors) => {
  if (value) {
    let updatedValue = value;
    // Iterate through the connectors array and replace connectorName in the string
    if (globalConnectors && globalConnectors.length > 0) {
      globalConnectors.forEach(function (connector) {
        var connectorName = connector.name;
        var connectorValue = connector.value;
        var regex = new RegExp(
          "NEC_EXEC_ENV,connectorId='" + connectorValue + "'",
          "g"
        );
        updatedValue = updatedValue.replace(
          regex,
          "connectorName='" + connectorName + "'"
        );
      });
    }
    return updatedValue;
  }
};

export const prepareCodeString = (value, mode) => {
  if (mode === "json" || mode === "list") {
    const updatedJSONString = `var a=${value} \n a;`;
    return updatedJSONString;
  }
  return value;
};

// type -> editor mode
// Description -> Based on editor mode this function returns System Behaviour for AI
export const getSystemBehaviourForAi = (type) => {
  switch (type) {
    case "javascript":
      return `You are a javascript assistant developer. Provide javascript response in json form.`;
    default:
      return "response in json form";
  }
};
