import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import NectedEditor from './editor';
import { Toaster } from "./components/Toaster";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));


if(process.env.REACT_APP_ENV === "production"){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <NectedEditor />
      <Toaster />        
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
