/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ReactJson from "react-json-view";
import "./editor.css";
import React, { useEffect, useState } from "react";
import "./mongo/theme";
import { Completer } from "./mongo/completer";
import { Parser } from "./mongo/parser";
import { validateMongoQuery } from "./mongo/validateMongoQuery";
import { validateSQLQuery } from "./sql/validateSQLQuery";
import {
  CHARACTER_NOT_ALLOWED,
  CUSTOM_TOKENS_REGEX,
  CUSTOM_TOKENS_REGEX_WITH_QUOTES,
  DATA_TYPES_VALUE,
  EDITOR_OPTIONS,
  documentationPropertiesData,
  MODULE_MAP,
  collateSuggestions,
  convertDomains,
  convertValues,
  detectType,
  editorTooltip,
  executeJS,
  extractConnectorName,
  extractTokens,
  helperFunctions,
  log,
  removeComments,
  removeExtraQuotes,
  returnBodyType,
  sanitizedString,
  validateFunctionSignature,
  validateListMacrosSignature,
  MethodsMap,
  testJSON,
  timezoneSuggestions,
  functionSignatures,
  replaceRCIDWithName,
  prepareCodeString,
  replaceRCNameWithID,
  isMacroFunction,
} from "./utils/utils";
import { RiFormula } from "react-icons/ri";
import { IoIosHelpCircle } from "react-icons/io";
import { validateJS } from "./js/validateJSString";
import { validateJSON } from "./json/validateJSONString";
import { ContextMenu } from "./components/contextMenu";
import { SelectList } from "./components/selectList";
import { NectedJSONEditor } from "./components/json-editor";
import { validateListString } from "./list/validateListString";
import PopUp from './components/Popup';
import AskAISuggestion from './components/AskAISuggestion';
import "react-tooltip/dist/react-tooltip.css";
import { FaPlay } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { ExplainCodeAI } from "./components/ExplainCodeAI";
const ALLOWED_ORIGIN = convertDomains();

/** 
  Default value of editor and tooltip in Global Namespace
*/

var editor = null;
var tooltip = null;

// Why do we need these as global becasue eventlistners dosent have the updated state values
// so on each state update gvars will have the updated values. can use Usecallback here too - @subhanshu
// bu this is simple.
var IgnoreFirstChange = 0;
var FirstValue = 0;
var editorFirstString = "";
var globalCompleters = [];
var globalConnectors = [];
var oldEditorValue = "";
var globalTokenMap = {};
/* ****** End of Global Namespace  */

const GUI_MODE = ["json", "list"];

function NectedEditor() {
  const [editorMode, setEditorMode] = useState("");
  const [variant, setVariant] = useState("multi");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [methods, setMethods] = useState(["read"]);
  const [isErrorFree, setIsErrorFree] = useState(true);
  const [allAutocomplete, setAllCompleters] = useState([]);
  const [restConnectors, setRestConnectors] = useState([]);
  const [, setSelectedConnector] = useState(null);
  const [displayMode, setDisplayMode] = useState("code");
  const [selectedRow, setSelectedRow] = useState(null);
  const [isRunCode, setIsRunCode] = useState(false);
  const [runCodeData, setRunCodeData] = useState("");
  const [openSelectTokenList, setOpenSelectTokenList] = useState(false);
  const [tooltipValue, setTooltipValue] = useState({
    type: "",
    executedValue: "",
  });
  const [documentationLink, setDocumentationLink] = useState("");
  const [isInfoMenuOpen , setIsInfoMenuOpen] = useState(true);
  const [nodeCodeData , setNoCodeData] = useState(null);
  const [preSelectedFormulaData , setPreSelectedFormulaData] = useState(null);

  const [showSuggestionOptions, setShowSuggestionOptions] = useState(false);
  const [suggestionType, setSuggestionType] = useState("")
  const cursorPosition = editor?.getCursorPosition() ?? {};
  /** 
     Important parameters to load editor.
     We set the states of the editor 
     @mode = js | json | mysql | postgres | text | mongodb - string
     @methods = read | write | read,write - string
     @readOnly  = true | false - boolean

     Without these paramters Iframe will not load and will show the logo of nected.
  */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mode_params = urlParams.get("mode") ?? "";
    const readOnly_params = urlParams.get("readOnly") === "true" ? true : false;
    const methods_params = urlParams.get("methods") ?? "";
    const variant_params = urlParams.get("variant") ?? "multi";
    setMethods(methods_params.split(","));
    setIsReadOnly(readOnly_params);
    setEditorMode(mode_params);
    setVariant(variant_params);
    log(
      `Mode: ${mode_params} | ReadOnly: ${readOnly_params} | Methods: ${methods_params}`
    );
  }, []);
  

   useEffect(() =>{
    if(displayMode === "code"){
      updateEditorValueFromNoCode(nodeCodeData);
    }
  },[displayMode])

  const updateEditorValueFromNoCode = (data) =>{
    if(data){
      beautifyAndSetValue(handleCodeStringForEditor(JSON.stringify(data, null, 2), "replaceQuotes"), editorMode);
      // injectCompleters(allAutocomplete, editorMode, false);
    }
  }


  /**
    When we have the value of the editorMode which we set on load.
    Now we can start initializing our ace editor.
    * @param {string} - editor mode
  */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const variant_params = urlParams.get("variant") ?? "multi";
    const readOnly_params = urlParams.get("readOnly") === "true" ? true : false;
    // Attach event listner to receive post messages from Konark or any other callee (Parent)
    window.addEventListener("message", receiveMessageFromFrame, false);
    tooltip = document.getElementById("token-tooltip");

    /*
      As we are loading ace and other dependencies from the CDN we really dont know when 
      the editor objects are initialized so we just check if the editor and its required depedencies are available
      we start loading the editor basis @mode, @methods.
    */
    if (
      window.ace &&
      window.LanguageProvider &&
      editorMode !== "" &&
      displayMode === "code"
    ) {
      // this language_tools pluin give the editor autocomplete capability.
      window.ace.require("ace/ext/language_tools");
      editor = window.ace.edit("nected-editor");
      window.NectedEditor = editor;
      const updatedEditorOptions = EDITOR_OPTIONS;
      if (variant_params === "single") {
        updatedEditorOptions.maxLines = 1;
        updatedEditorOptions.showGutter = false;
        updatedEditorOptions.showLineNumbers = false;
      }
      editor.setOptions(updatedEditorOptions);

      // **** START of Editor Options ****
      // Various our editor options you can set here

      editor.setShowPrintMargin(false);
      editor.setReadOnly(readOnly_params);
      editor.getSession().setUseWorker(false);

      // **** END of Editor Options ****

      /*
          Language aware features of editor were autocomplete capabilities gets enabled
          We only want the Autocomplete capabities for JS and JSON.
          We have just configured and loaded the Linters from CDN (Ace-linters)
          We have disabled the diagnostics of JS (Linting) as we are using our own version of lint ie. prebuilt version of eslint.
      */
      if (
        ['json', 'js' , 'formula' , 'list'].includes(editorMode)
      ) {
        var provider = window.LanguageProvider.fromCdn(
          "https://www.unpkg.com/ace-linters@1.2.0/build/"
        );
        provider.configureServiceFeatures("javascript", {
          completion: true,
          completionResolve: true,
          diagnostics: false,
          format: false,
          hover: false,
        });
        provider.configureServiceFeatures("typescript", {
          diagnostics: false,
        });
        provider.configureServiceFeatures("json", {
          diagnostics: false,
        });
        provider.setGlobalOptions("typescript", {
          compilerOptions: {
            allowJs: false,
            checkJs: false,
            target: 99,
            jsx: 1,
          },
        });
        provider.registerEditor(editor);
      }

      /** ***** END of Language Capabilities Settings ****** */

      // This function enables mode selection and various other settings with respect to editor.
      // Mode Specific Settings.
      handleEditorMode(window.ace, editor, editorMode);

      //Internal event listener of Editor.
      editor.getSession().on("change", function () {
        handleEditorChange(editorMode, [], restConnectors);
      });

      attachCursorPositionChangeEvent();
      updateAskAICoordinates();


      // if maxLines is being sent handle
      if (variant_params === "single") {
        registerSingleLine();
      }

      // functions helps us to remove Double Quotes form the stings in case of Custom Tokens {{.xyz.xyz}}
      removeDQInString();

      // This is to hide the tooltip for the above funtion renderTooltipOnHover.
      renderTooltipOnHover();

      registerSelectConnectorClickMethod();

      registerSelectListTokenClickMethod();

      // editor.container.addEventListener("mouseleave", hideTooltip);
    }
    return () => {
      // We remove the post message event listener on unmount of editor,
      window.removeEventListener("message", receiveMessageFromFrame, false);
    };
  }, [editorMode]);

  const getPixelPostion = () => {
    if (!editor || !editor.session) {
      return { left: 0, top: 0 };
    } else {
      const position = editor.session.selection.getCursor();

      const column = editor.session.getLine(position.row).length;
      var pos = editor.session.documentToScreenPosition({
        row: position.row, column
      });

      var cursorLeft = pos.column * 10; 
      var cursorTop = (pos.row - (0)) * 10; 

      return { left: cursorLeft, top: cursorTop };
    }
  }

  const registerSelectConnectorClickMethod = () => {
    editor.on("click", function (e) {
      var position = editor.getCursorPosition();
      var token = editor.session.getTokenAt(position.row, position.column);

      var contextMenu = document.getElementById("context-menu");

      if (token && token.value === "select_connector") {
        // Open the context menu at the clicked position
        contextMenu.setAttribute("data-menumode", "create");
        contextMenu.style.display = "flex";

        getContextMenuPos(e.clientX, e.clientY, contextMenu);
      } else if (token && token.type === "connector_name") {
        contextMenu.setAttribute("data-menumode", "update");
        contextMenu.style.display = "flex";

        getContextMenuPos(e.clientX, e.clientY, contextMenu);
      }
      else if (token && token.value && isMacroFunction(token.value) && token.type === "nected_helper_function"){
        const macroName = `${token.value}()`;
        const infoDocument  = documentationPropertiesData[0].formulas.filter((i) => i.name === macroName)[0];
        if(infoDocument){
          setPreSelectedFormulaData(infoDocument);
          setIsInfoMenuOpen(true);
          setTimeout(() =>{
            if(document.getElementById(macroName)){
              // document.getElementById(macroName).scrollIntoView({ behavior: 'smooth' });
            }
          },100)
        }
      }
      else {
        contextMenu.style.display = "none";
      }
    });
  };

  const resetPreSelectedFormulaData = () =>{
    setPreSelectedFormulaData(null);
  }

  const registerSelectListTokenClickMethod = () => {
    editor.on("click", function (e) {
      var position = editor.getCursorPosition();
      var token = editor.session.getTokenAt(position.row, position.column);
      var contextMenu = document.getElementById("token-menu");
      if (token && ["select_list_token", "select_date_token", "select_unit"].includes(token.value)) {
        setOpenSelectTokenList(true);
        // Open the context menu at the clicked position
        contextMenu.setAttribute("data-menumode", "create");
        contextMenu.setAttribute("data-type", token.value);
        contextMenu.style.display = "flex";

        getContextMenuPos(e.clientX, e.clientY, contextMenu);
      } else {
        setOpenSelectTokenList(false);
        contextMenu.style.display = "none";
      }
    });
  };

  const getContextMenuPos = (clientX, clientY, contextMenu) => {
    const viewportMidpointY = window.innerHeight / 2;
    if (clientY > viewportMidpointY) {
      // If below, open the popup above the clientX and clientY position
      contextMenu.style.left = clientX + "px";
      contextMenu.style.top = clientY - 15 - contextMenu.offsetHeight + "px";
    } else {
      // If above, open the contextMenu below the clientX and clientY position
      contextMenu.style.left = clientX - 45 + "px";
      contextMenu.style.top = clientY + 15 + "px";
    }
  };

  const registerSingleLine = () => {
    // remove newlines in pasted text
    editor.on("paste", function (e) {
      e.text = e.text.replace(/[\r\n]+/g, " ");
    });
    // make mouse position clipping nicer
    editor.renderer.screenToTextCoordinates = function (x, y) {
      var pos = this.pixelToScreenCoordinates(x, y);
      return this.session.screenToDocumentPosition(
        Math.min(this.session.getScreenLength() - 1, Math.max(pos.row, 0)),
        Math.max(pos.column, 0)
      );
    };
    // disable Enter Shift-Enter keys
    editor.commands.bindKey("Enter|Shift-Enter", "null");
  };

  /**
   * Function to show hide the tooltip on hover
   * Currently this is in disabled state
   * We are not showing this developed it as an extra feature - @subhanshu
   */
  const renderTooltipOnHover = () => {
    editor.on("click", handleClick);
    editor.on("mousemove", handleMouseMove);
  
    function handleClick(e) {
      const { token, position } = getTokenAndPosition(e);
      if (token && isTokenValid(token, CUSTOM_TOKENS_REGEX_WITH_QUOTES, CUSTOM_TOKENS_REGEX)) {
        let tokenValue = globalCompleters.find((j) => j.value.includes(token.value));
  
        if (!tokenValue) {
          tokenValue = globalTokenMap[token.value];
        }
  
        if (tokenValue) {
          const type = tokenValue.executedValue !== "" ? detectType(tokenValue.executedValue) : tokenValue.meta;
          const executedValue = tokenValue.executedValue !== "" ?
            convertValues(detectType(tokenValue.executedValue), tokenValue.executedValue, tokenValue.meta) :
            DATA_TYPES_VALUE[tokenValue.meta];
  
          if (executedValue !== JSON.stringify(tooltipValue.executedValue)) {
            setTooltipValue({
              type,
              executedValue: (type === "array" || type === "object") ? JSON.parse(executedValue) : executedValue,
            });
            showTooltip(e, ``);
          }
        }
      }
    }
  
    function handleMouseMove(e) {
      const { token, position } = getTokenAndPosition(e);
  
      if (token && !CHARACTER_NOT_ALLOWED.includes(token.type) && !CHARACTER_NOT_ALLOWED.includes(token.value)) {
        if (token.type === "identifier" && token.value === "urlPath") {
          const currentLine = getCurrentLineBasedOnBoundingClient(e);
          const connectorObj = globalConnectors.filter((i) => i.name === extractConnectorName(getValueFromLine(currentLine)));
  
          if (connectorObj.length > 0) {
            showTooltip(e, `<pre><b>Base URL</b>
              Staging : ${connectorObj[0].stagBaseURL ? connectorObj[0].stagBaseURL : "Not Added Yet"}
              Production : ${connectorObj[0].prodBaseURL ? connectorObj[0].prodBaseURL : "Not Configured"}</pre>`);
          }
        } else {
          // hideTooltip();
        }
      } else {
        // hideTooltip();
      }
    }
  
    function getTokenAndPosition(e) {
      const position = e.getDocumentPosition();
      const token = editor.getSession().getTokenAt(position.row, position.column);
      return { token, position };
    }
  
    function isTokenValid(token, regexWithQuotes, regexWithoutQuotes) {
      return token &&
        (token.type === "string" || token.type === "nected_token") &&
        (regexWithQuotes.test(token.value) || regexWithoutQuotes.test(token.value));
    }
  };
  

  const showTooltip = (pos, content) => {
    if (tooltip !== null) {
      tooltip.style.top = pos.clientY + 10 + "px";
      tooltip.style.left = pos.clientX + 10 + "px";
      tooltip.style.display = "block";
      tooltip.style.position = "absolute";
      tooltip.style.zIndex = "100";
    }
  };

  const hideTooltip = () => {
    setTooltipValue({
      type: "",
      executedValue: "",
    });
    if (tooltip !== null) {
      tooltip.style.display = "none";
    }
  };

  const getCurrentLineBasedOnBoundingClient = (event) => {
    let x = event.clientX;
    let y = event.clientY;
    let pos = editor.renderer.screenToTextCoordinates(x, y);

    return pos.row + 1;
  };

  const getValueFromLine = (lineNumber) => {
    if (lineNumber <= 0) {
      return "";
    }
    let session = editor.getSession();
    let line = session.getLine(lineNumber - 1); // Line numbers are 0-based in Ace Editor

    return line;
  };

  /**
   * This function removed the double quote from a string in case of custom tokens
   * For example "This is a dummy text "{{xyz.xyz}}"" would result to
   * "This is a dummy text {{.xyz.xyz}}" (String inside a String would result in error)
   */
  const removeDQInString = () => {
    editor.commands.on("afterExec", function (e) {
      if (e.command.name === "insertstring") {
        var cursorPos = editor.getCursorPosition();
        var line = editor.session.getLine(cursorPos.row);
        var token = editor.session.getTokenAt(cursorPos.row, cursorPos.column);
        var pattern = CUSTOM_TOKENS_REGEX_WITH_QUOTES;
        if (pattern.test(e.args) && token) {
          editor.session.replace(
            {
              start: { row: cursorPos.row, column: 0 },
              end: { row: cursorPos.row, column: Number.MAX_VALUE },
            },
            removeExtraQuotes(line)
          );
        }
      }
    });
  };

  /**
   *
   * @param {*} event
   * Listens to all the events which are sent by Parent Iframe.
   * Only listens to ALLOWED ORIGINS
   */
  const receiveMessageFromFrame = (event) => {
    if (!ALLOWED_ORIGIN.includes(event.origin)) {
      return;
    }
  
    let { type, data, mode } = event.data;
  
    switch (type) {
      case "SUGGESTION":
        setTimeout(() => {
          injectCompleters(data, mode, true);
        }, 350);
        break;
  
      case "EDITOR_INFO":
        setDocumentationLink(data.documentation);
        break;
  
      case "FOUND_EXEC_VALUE":
        globalTokenMap = data;
        break;
  
      case "REST_CONNECTORS":
        setRestConnectors(data);
        globalConnectors = data;
        break;
  
      case "SET_VALUE":
        if (FirstValue === 0) {
          if (editorMode === "list" && Array.isArray(data)) {
            data = JSON.stringify(data);
          }
          findExecutedValue(data, editorMode);
          editorFirstString = replaceRCIDWithName(data);
        }
        break;
  
      case "RESET_VALUE":
        beautifyAndSetValue(replaceRCIDWithName(data), mode);
        break;
  
      case "EXECUTE_JS_FORMULA":
        executeJSFormula(data.suggestions, mode);
        break;
  
      case "SET_AUTH":
        FirstValue=0;
        setAuthDetails(data);
        break;
  
      default:
        break;
    }
  };
  

  useEffect(() => {
    if (restConnectors.length > 0) {
      let updatedSuggestions = globalCompleters;
      updatedSuggestions.push({
        name: "rest_api_connector",
        value: "rest_api_connector(select_connector);",
        meta: "Rest API Function",
        score: 999,
      });
      let updateCompleters = [...editor.completers];
      editor.completers = [
        ...updateCompleters,
        ...[
          {
            getCompletions: function (editor, session, pos, prefix, callback) {
              callback(null, [...updatedSuggestions, ...helperFunctions]);
            },
          },
        ],
      ];
    }
    return () => {};
  }, [restConnectors]);

  /**
   *
   * @param {Object} data - Object containing the tokens
   * @param {string} data.at - Access token, aliased as syr
   * @param {string} data.ws - Workspace token
   * To use the Linitng API which sits behind the Authenticated Server
   * Parent Iframe Sends 2 parameters "Acess Token" as at and "Workspace Token" as ws
   * Payload type for this is SET_AUTH.
   * Used to send in header as Authorizatin : Bearer {ws} and Nected-ws : {ws} in Linting API.
   */
  const setAuthDetails = (data) => {
    if (data.at) {
      window.localStorage.setItem("accessToken", data.at);
    }
    if (data.ws) {
      window.localStorage.setItem("workspaceUUID", data.ws);
    }
  };

  /**
   *
   * @param {integer} timeout
   * @param {Array} suggestions - Array of user suggestion (used in autocomplete)
   * @param {string} suggestions[].meta - details about property/ token / keywords
   * @param {string} suggestions[].name - name which is to displayed in autocomplete
   * @param {string} suggestions[].value - value which will be injected in editor at cursor position
   * @param {number} suggestions[].score -  helps in ordering of suggestions by editor (internal)
   *
   * This methods takes timeout as a paramter if not passed 1500 is the default
   * Executes the JS and return type and data as a response and sends back to Parent Iframe.
   * All the eval is being called in Web Worker as seprate thread.
   * Suggestions is being used to replace the custom token to there values
   *
   * But after the integration of executedValue suggestions would not be required
   * Parent Iframe would need to send only the expecuted value of the dependent tokens.
   * Payload type of this is EXECUTE_JS_FORMULA
   *
   */
  const executeJSFormula = (suggestions = [], mode, from = "") => {
    if (from === "run") {
      setIsRunCode(true);
    }

    let value = editor.session.getValue()
    if(from === "run" && displayMode === "gui"){
      value = handleCodeStringForEditor(JSON.stringify(nodeCodeData), "replaceQuotes");
    }

    const payload = {
      language: "JS",
      snippet: replaceRCNameWithID(
        prepareCodeString(
          sanitizedString(
            value,
            suggestions,
            globalTokenMap,
            from === "run" ? ''  : "execute"
          ),
          mode
        ), globalConnectors
      ),
    };
    executeJS(payload)
      .then((data) => {
        if (from === "run") {
          setRunCodeData(data.data);
          setIsRunCode(false);
          // setIsInfoMenuOpen(false)
          return;
        }

        const payload = {
          type: "EXECUTION_COMPLETED",
          data: data.data.result,
          value: replaceRCNameWithID(editor.session.getValue() , globalConnectors),
          dataType: detectType(data.data.result),
          status: "SUCCESS",
        };

        sendMessageToFrame(payload);
        if (data.data.result === undefined) {
          const payload = {
            type: "ONCHANGE",
            data: replaceRCNameWithID(editor.session.getValue(), globalConnectors),
            mode,
            isValid: false,
          };
          setIsErrorFree(false);
          const allAnnotations = editor.getSession().getAnnotations();
          allAnnotations.push({
            row: 0,
            column: 0,
            text: "Please handle undefined cases in your code.",
            type: "error",
          });
          editor.getSession().setAnnotations(allAnnotations);
          sendMessageToFrame(payload);
        }
      })
      .catch((error) => {
        if (from === "run") {
          setRunCodeData(error.data);
          setIsRunCode(false);
          return;
        }
        const payload = {
          type: "EXECUTION_COMPLETED",
          data: error,
          dataType: undefined,
          status: "ERROR",
        };
        sendMessageToFrame(payload);
      });
  };

  /**
   *
   * @param {*} payload
   *
   * This function is used to send data/response back to the parent iframe.
   */
  const sendMessageToFrame = (payload) => {
    window.parent.postMessage(payload, "*");
  };

  /**
   *
   * @param {string} value - Editor Value
   * @param {string} mode - Editor Mode
   *
   * We format / Sanitize the value before we set it to editor
   * We have used Prettier and Formater for all the supported Languages
   * Disabled it for SQL based queries for now - @subhanshu
   * FirstValue Global Var is being use here because When we set the editor for the first time onchange event
   * is fired internally which causes the Konark to call the onchange autosave event and hence move it to DRAFT state
   * For now it solves the problem can look for better approach - @subhanshu
   * Payload type for this is SET_VALUE
   *
   */
  const beautifyAndSetValue = (value, mode, from = "") => {
    let editorValue = value ?? "";
  
    if (['js', 'json', 'list', 'formula'].includes(mode)) {
      if (mode === "json") {
        try {
          editorValue = JSON.stringify(JSON.parse(value ?? "{}"), null, 2);
        } catch (e) {
          editorValue = value ?? "{}";
        }
      }
      if (mode === "list") {
        try {
          editorValue = value ?? "[]";
        } catch (e) {
          console.log("Error", e);
          editorValue = JSON.stringify(value, null, 2);
        }
      }
    } else if (mode === "mongodb") {
      const parser = new Parser();
      editorValue = value === null || value === "null" || value === undefined ? "" : value;
    }
  
    FirstValue++; // Assuming FirstValue is declared and initialized somewhere else
  
    findExecutedValue(editorValue, mode);
  
    if (mode === "list") {
      editor.session.setValue(editorValue);
    } else {
      if (editor && !methods.includes("write")) {
        editor.session.setValue(editorValue);
      } else if (editor && value !== undefined) {
        editor.session.setValue(value);
      }
    }
  };

  /**
   *
   * @param {Array} payload - Array of user suggestion (used in autocomplete)
   * @param {string} payload[].meta - details about property/ token / keywords
   * @param {string} payload[].name - name which is to displayed in autocomplete
   * @param {string} payload[].value - value which will be injected in editor at cursor position
   * @param {number} payload[].score -  helps in ordering of suggestions by editor (internal)
   * @param {*} mode  - Editor Mode
   *
   * On the basis of the mode we attach the suggestions
   * in terms of ACE editor we call this completers.
   * This completer function is only enabled when we include "ace/ext/language_tools"
   *
   * Also after adding the completers we attach the validation methods for every mode.
   * Which helps us to show the linting errors the moment we set the value for the first time
   * We have diffrent validation methods for different modes
   * @validateJSON for JSON
   * @validateListString for List/Array
   * @validateJS for JS
   * @validateMongoQuery for MongoQueries
   * @validateSQLQuery for all the SQL related dialects
   *
   * Payload type for this is SUGGESTION
   *
   */

  const injectCompleters = (payload, mode, setValue = false) => {
    const filteredPayload = payload.filter((i) => i.score !== undefined);
    const suggestions = collateSuggestions(false, filteredPayload, mode || editorMode, methods);

    setAllCompleters(suggestions);
    globalCompleters = suggestions;

    const initializeEditor = () => {
        if (setValue && editorFirstString !== "") {
            beautifyAndSetValue(editorFirstString, mode);
        }

        if (editorFirstString && (editorMode === "json" || editorMode === "list")) {
            try {
                setNoCodeData(JSON.parse(handleCodeStringForEditor(processValue(editorFirstString), "addQuotes")));
            } catch (err) {
                setNoCodeData(editorMode === "json" ? {} : []);
            }
        }
    };

    const handleMongoDBMode = () => {
        const parser = new Parser();
        const collectionArray = payload.filter((i) => i.meta === "collection").map((i) => i.value);
        parser.setCollections(collectionArray);
        const completer = new Completer({
            parser: parser,
            fieldSuggestions: payload.filter((i) => i.meta !== "collection"),
        });
        const mongoSuggestions = payload.filter((i) => i.meta !== "collection");
        editor.completers = [completer.queryCompleter];
        setAllCompleters(mongoSuggestions);
        globalCompleters = mongoSuggestions;
        const validateQuery = () => validateMongoQuery(
            parser,
            editor.session,
            "query",
            handleEditorChange,
            mongoSuggestions,
            restConnectors,
            globalTokenMap
        );

        validateQuery();
        editor.session.on("change", () => setTimeout(validateQuery, 500));
    };

    const handleJSOrFormulaMode = () => {
        const getCompleter = () => ({
            getCompletions: function (editor, session, pos, prefix, callback) {
                const curPos = editor.getCursorPosition();
                const currentLine = session.getLine(curPos.row);

                const isWithinParens = (line, cursorPos) => {
                    const nowIndex = line.indexOf("NOW(");
                    const todayIndex = line.indexOf("TODAY(");
                    const indexes = [];

                    if (nowIndex !== -1) {
                        const nowEndIndex = line.indexOf(")", nowIndex + 4);
                        if (nowEndIndex !== -1) indexes.push({ start: nowIndex + 4, end: nowEndIndex });
                    }

                    if (todayIndex !== -1) {
                        const todayEndIndex = line.indexOf(")", todayIndex + 6);
                        if (todayEndIndex !== -1) indexes.push({ start: todayIndex + 6, end: todayEndIndex });
                    }

                    return indexes.some(({ start, end }) => cursorPos.column >= start && cursorPos.column <= end);
                };

                if (isWithinParens(currentLine, curPos)) {
                    callback(null, timezoneSuggestions);
                } else {
                    callback(null, suggestions.filter((i) => i.display !== false));
                }
            },
            getExactMatch: true,
        });

        editor.completers = [...editor.completers, getCompleter()];

        const validate = () => validateJS(editor.session, mode, handleEditorChange, suggestions, restConnectors, globalTokenMap);
        validate();
        editor.session.on("change", () => setTimeout(validate, 500));
    };

    const handleJSONOrListMode = (validateFn) => {
        const getCompleter = () => ({
            getCompletions: (editor, session, pos, prefix, callback) => callback(null, suggestions),
            getExactMatch: true,
        });

        editor.completers = [getCompleter()];

        const validate = () => validateFn(editor.session, mode, handleEditorChange, suggestions, restConnectors, globalTokenMap);
        validate();
        editor.session.on("change", () => setTimeout(validate, 500));
    };

    const handleDefaultMode = () => {
        const getCompleter = () => ({
            getCompletions: (editor, session, pos, prefix, callback) => callback(null, suggestions),
            getExactMatch: true,
        });

        editor.completers = [getCompleter()];

        const validate = () => validateSQLQuery(editor.session, editorMode, handleEditorChange, suggestions, restConnectors, globalTokenMap);
        validate();
        editor.session.on("change", () => setTimeout(validate, 500));
    };

    initializeEditor();

    switch (mode) {
        case "mongodb":
            handleMongoDBMode();
            break;
        case "js":
        case "formula":
            handleJSOrFormulaMode();
            break;
        case "json":
            handleJSONOrListMode(validateJSON);
            break;
        case "list":
            handleJSONOrListMode(validateListString);
            break;
        case "text":
            handleJSONOrListMode(() => {}); // No validation function for text
            break;
        default:
            handleDefaultMode();
            break;
    }

    injectMACROS();
  };



  const injectMACROS = () =>{
      let updatedSuggestions = globalCompleters;
      let updateCompleters = [...editor.completers];
      editor.completers = [...updateCompleters, ...[
        {
          getCompletions: function (editor, session, pos, prefix, callback) {
            callback(null, [...updatedSuggestions, ...helperFunctions]);
          },
          getDocTooltip : function (item) {
            const func = functionSignatures[item.name];
            if (func) {
                return `${func.signature}\n${func.description}`;
            } else if (item.meta !== "") {
                return `Token of type: ${item.meta}`;
            }
          }
        }],
      ];
  }

  /**
   *
   * @param {Object} ace - Ace editor object
   * @param {*} editor  - Initialized Editor Object
   * @param {*} mode - Editor Mode
   *
   * Function just set the mode of the editor basis editorMode state.
   * if passes unknown mode raises an alert
   */
  const handleEditorMode = (ace, editor, mode) => {
    const session = editor.getSession();
    const module = MODULE_MAP[mode];
  
    switch (module) {
      case "js":
      case "formula":
        editor.session.setMode("ace/mode/javascript");
        break;
      case "pgsql":
      case "mysql":
      case "redshift":
      case "bigquery":
      case "sqlserver":
      case "snowflake":
      case "oracle":
        editor.setTheme("ace/theme/sqlserver");
        editor.session.setMode(`ace/mode/${module}`);
        break;
      case "json5":
        editor.session.setMode("ace/mode/json5");
        break;
      case "mongo":
        session.setMode("ace/mode/mongo");
        editor.setTheme("ace/theme/mongo");
        break;
      case "text":
      case "list":
        editor.session.setMode("ace/mode/text");
        break;
      default:
        alert("Valid mode missing.");
        return; // Exit function early in case of invalid mode
    }
  
    void registerNectedTokens(); // Assuming this function call is necessary
  };
  

  /**
   * This functions helps in detecting the nected token format in the editor
   * Also highlights by adding nected_token as class name.
   */
  const registerNectedTokens = () => {
    const rules = editor.session.$mode.$highlightRules.getRules();
    const regexRules = [
      { token: "nected_token", regex: "{{\\s*(?:\\.[a-zA-Z0-9-_]+(?:\\[[^\\]]+\\])*)+\\s*}}" },
      {
        token: "nected_helper_function",
        regexes: [
          "\\bMAX_LIST\\b", "\\bMIN_LIST\\b", "\\bAVG_LIST\\b", "\\bSUM_LIST\\b",
          "\\bMAX\\b", "\\bMIN\\b", "\\bAVG\\b", "\\bSUM\\b",
          "\\bDAY\\b", "\\bMONTH\\b", "\\bYEAR\\b", "\\bHOUR\\b", "\\bMINUTE\\b",
          "\\bSECOND\\b", "\\bNOW\\b", "\\bTODAY\\b", "\\bDATEDIFF\\b", "\\bDATECOMPUTE\\b","\\bDATEFORMAT\\b","\\DATEPARSE\\b",
          "\\bFILTER\\b", "\\bLIMIT\\b", "\\bSORT\\b", "\\bSQRT\\b", "\\bPOW\\b",
          "\\bPMT\\b", "\\bPV\\b", "\\bEMI\\b", "\\bWEEK\\b", "\\bCOUNT\\b" , "\\DISTINCT\\b"
        ]
      },
      { token: "rest_api_connector", regex: "\\bselect_connector\\b" },
      { token: "select_key", regex: "\\bselect_key\\b" },
      { token: "select_list_token", regex: "\\bselect_list_token\\b" },
      { token: "select_unit", regex: "\\bselect_unit\\b" },
      { token: "select_date_token", regex: "\\bselect_date_token\\b" },
      { token: "fn_rest_api_connector", regex: "\\brest_api_connector\\b" },
      { token: "connector_name", regex: "connectorName='([^']+)'," }
    ];
  
    for (const stateName in rules) {
      if (Object.prototype.hasOwnProperty.call(rules, stateName)) {
        regexRules.forEach(({ token, regex, regexes }) => {
          if (regex) {
            rules[stateName].unshift({ token, regex });
          }
          if (regexes) {
            regexes.forEach((r) => rules[stateName].unshift({ token, regex: r }));
          }
        });
      }
    }
  
    editor.session.$mode.$tokenizer = null;
    editor.session.bgTokenizer.setTokenizer(editor.session.$mode.getTokenizer());
    editor.session.bgTokenizer.start(0);
  };
  
  

  /**
   *
   * @param {string} mode  - Editor Mode
   * @param {Object} annotations - Editor Annotations object
   *
   * Before sending back the data to the Parent Iframe the update value of the editor
   * We verify if the entered value is error free by checking the Linting errors - @validateValue - Method
   * checks whether on the basis of methods you have written query or not.
   * We verify the the entered value has tokens that are not invalid @validateTokens
   * In both the cases we throught the annotations errors to the editor and send isValid as false to the parent Iframe
   * so that save could be blocked.
   *
   * We are delaying this by 650 sec because ace-linter library that we imported fot autocompletion earlier
   * clearout the annotations as we have set diagnostics to false. so we wait for few ms and again set the annotations.
   *
   */

  /** ******** START OF @handleEditorChange SUPPORT FUNCTIONS  ******* */
  const handleEditorChange = (mode, annotations, restConnectors) => {
    hideTooltip();
    var ants = annotations;
    if (
      oldEditorValue !== editor.getSession().getValue() ||
      editor.getSession().getValue().length === 0
    ) {
      findExecutedValue(editor.getSession().getValue(), mode);
      if ((mode === "text" && IgnoreFirstChange >= 0) || IgnoreFirstChange > 1) {
        setTimeout(
          (ants, oldEditorValue, restConnectors) => {
            if(editor.getSession().getValue() !== ""){
              try{
                setNoCodeData(JSON.parse(handleCodeStringForEditor(processValue(editor.getSession().getValue()), "addQuotes")));
              }
              catch(err){}
            }
            let localRestConnectors = restConnectors;
            const value = editor.getSession().getValue();
            const isLintError = (ants || []).length === 0;
            const isValueValid = validateValue(
              sanitizedString(value, globalCompleters, globalTokenMap),
              isLintError
            );

            const isValidTokensUsed = validateTokens(
              value,
              globalCompleters,
              globalTokenMap
            );
            const isRestAPIUSed = validateRestAPICall(value);
            const isRestAPISignatureValid = validateRestAPISignature(value);
            const isRestAPINameValid = validateRestAPIName(
              value,
              localRestConnectors
            );
            const isListMacrosValid = validateListMACROSignatures(
              value,
              localRestConnectors
            );
            const isMacrosValid = true;
            let isListValid = true;
            const payload = {
              type: "ONCHANGE",
              data: replaceRCNameWithID(value , globalConnectors),
              mode,
              isValid:
                isValueValid &&
                isValidTokensUsed &&
                isRestAPIUSed &&
                isRestAPISignatureValid &&
                isRestAPINameValid &&
                isListMacrosValid &&
                isMacrosValid,
            };

            editor.getSession().setAnnotations(ants);
            sendPostMessage(payload);
            setIsErrorFree(
              isValueValid &&
                isValidTokensUsed &&
                isRestAPIUSed &&
                isRestAPISignatureValid &&
                isRestAPINameValid &&
                isListMacrosValid &&
                isMacrosValid
            );
          },
          350,
          ants,
          oldEditorValue,
          restConnectors
        );
      } else {
        setTimeout(
          (ants) => {
            editor.getSession().setAnnotations(ants);
          },
          2500,
          ants
        );
        IgnoreFirstChange = IgnoreFirstChange + 1;
      }
    }
  };

  const findExecutedValue = (value, mode) => {
    const tokenMap = {};
    const tokens = extractTokens(removeComments(value));
    tokens.forEach((i) => {
      tokenMap[i] = "";
    });
    if (Object.keys(tokenMap).length > 0) {
      sendPostMessage({
        type: "FIND_EXEC_VALUE",
        data: tokenMap,
        mode,
      });
    }
  };

  const sendPostMessage = (payload) => {
    window.parent.postMessage(payload, "*");
  };

  const validateTokens = (value, suggestions, tokenMap) => {
    const tokens = extractTokens(removeComments(value));
    let isValid = true;

    for (let i = 0; i < tokens.length; i++) {
      // const tokenValue = suggestions.find((j) => j.value.includes(tokens[i]));
      const tokenValue = tokenMap[tokens[i]]
        ? tokenMap[tokens[i]]
        : suggestions.find((j) => j.value.includes(tokens[i]));
      if (!tokenValue) {
        isValid = false;
        setTokensAnnotationErrors(
          "You have used some invalid custom tokens in your code."
        );
        break;
      }
    }

    return true;
  };

  const validateRestAPICall = (value) => {
    const pattern = /rest_api_connector\(select_connector\)/;

    // if(pattern.test(value)){
    //   setTokensAnnotationErrors("Please select a valid connector for rest_api_connector() function.");
    // }
    return !pattern.test(value);
  };

  const validateValue = (value, isValid) => {
    const SQL_WRITE_METHODS = ["INSERT", "UPDATE", "DELETE"];
    const MONGO_WRITE_METHODS = [
      "insertOne",
      "insertMany",
      "updateOne",
      "updateMany",
      "deleteOne",
      "deleteMany",
      "update",
      "insert",
      "delete",
    ];
  
    const checkWriteMethods = (methods, value) =>
      methods.some((method) => value.toLowerCase().includes(method.toLowerCase()));
  
    const setAndReturnError = (message) => {
      setAnnotationErrors(message, 0);
      return false;
    };
  
    if (methods.includes("read")) {
      if (["json", "js", "formula", "list"].includes(editorMode)) {
        return isValid;
      }
  
      if (editorMode === "mongodb" && checkWriteMethods(MONGO_WRITE_METHODS, value)) {
        return setAndReturnError("Write operations are not allowed");
      }
  
      if (editorMode !== "mongodb" &&  checkWriteMethods(SQL_WRITE_METHODS, value)) {
        return setAndReturnError("Write operations are not allowed");
      }
  
      return isValid;
    }
  
    if (editorMode === "json") {
      try {
        if (Array.isArray(JSON.parse(value))) {
          return setAndReturnError("Not a valid JSON");
        }
        return isValid;
      } catch {
        return setAndReturnError("Not a valid JSON");
      }
    }
  
    if (editorMode === "list") {
      try {
        if (!Array.isArray(JSON.parse(value))) {
          return setAndReturnError("Not a valid list");
        }
        else{
          JSON.parse(value);
          return true;
        }
      } catch {
        return setAndReturnError("Not a valid list");
      }
    }
  
    return isValid;
  };
  

  const validateRestAPIName = (value) => {
    const lines = value.trim().split("\n");
    const mismatchedConnectors = [];
    lines.forEach((line, lineNumber) => {
      const match = line.match(/connectorName=['"]([^'"]+)['"]/);
      if (match) {
        const connectorName = match[1];
        const found = globalConnectors.some(
          (nameObj) => nameObj.name === connectorName
        );
        if (!found) {
          mismatchedConnectors.push({ lineNumber, connectorName });
        }
      }
    });

    mismatchedConnectors.forEach((i) => {
      setTokensAnnotationErrors(
        `Cannot find connector with : ${i.connectorName} name.`,
        i.lineNumber
      );
    });
    return !mismatchedConnectors.length > 0;
  };

  const setAnnotationErrors = (message = "", row = 0) => {
    setTimeout(() => {
      const allAnnotations = editor.getSession().getAnnotations();
      allAnnotations.push({
        row: row,
        column: 0,
        text: message,
        type: "error",
      });
      editor.getSession().setAnnotations(allAnnotations);
    }, 375);
  };

  const setTokensAnnotationErrors = (message = "", row = 0) => {
    setTimeout(() => {
      const allAnnotations = editor.getSession().getAnnotations();
      allAnnotations.push({
        row: row,
        column: 0,
        text: message,
        type: "error",
      });
      editor.getSession().setAnnotations(allAnnotations);
    }, 575);
  };


  const removeTokensAnnotationErrors = (message = "", row = 0) => {
    setTimeout(() => {
      const allAnnotations = editor.getSession().getAnnotations();
      allAnnotations.filter((i) => i.message === message);
      editor.getSession().setAnnotations(allAnnotations.filter((i) => i.message === message));
    }, 575);
  };

  /** ******** END OF @handleEditorChange SUPPORT FUNCTIONS  ******* */

  const handleInsertSuggestion = (valueToInsert) => {
    const cursorPosition = editor.getCursorPosition();
    editor.session.insert(cursorPosition, valueToInsert);

    setSuggestionType("");
  }

  const attachCursorPositionChangeEvent = () => {
    editor.getSession().selection.on('changeCursor', function () {
     updateAskAICoordinates();
    });
  }

  const updateAskAICoordinates = () => {
    const coordinates = getCoordinatesForAskAIBox();

    const element = document.getElementById("ask-ai");
    const elementWidth = element?.getBoundingClientRect()?.width;

    if (element != null) {
      element.style.top = `${coordinates.top - 7}px`;
      element.style.left = `${coordinates.left - elementWidth - 8}px`;
    }
  }

  const getCoordinatesForAskAIBox = () => {
    const cursorPosition = editor.getCursorPosition();
    const screenPos = editor.renderer.textToScreenCoordinates(cursorPosition.row  + 2, cursorPosition.column);
    
    return {
      left: editor.renderer.container.clientWidth,
      top: screenPos.pageY
    };
  }
 
  const  getCursorPositionInPixels = () =>{
    const cursorPosition = editor.getCursorPosition();
    const screenPos = editor.renderer.textToScreenCoordinates(cursorPosition.row, cursorPosition.column);
    return {
      left: findMaxColumnPixelPosition(editor),
      top: screenPos.pageY
    };
  }

  const  findMaxColumnPixelPosition = (editor) => {
    let maxColumn = 0;
    // Get all lines in the editor
    const lines = editor.getSession().getDocument().getAllLines();
    // Iterate through each line to find the longest
    lines.forEach(line => {
      maxColumn = Math.max(maxColumn, line.length);
    });
    // Get the width of a single character in pixels
    const charWidth = editor.renderer.characterWidth;
    // Convert the max column position to pixels
    return maxColumn * charWidth;
  }

  const openAskAISuggestionBox = () =>{
    const cursorPosition = editor.getCursorPosition();
  
    const lineHeight = editor.renderer.lineHeight; // Get the height of a single line
    const topPosition = cursorPosition.row * lineHeight;

    const container = document.querySelector('.nected-editor-container');
    const parentContainerHeight = container?.getBoundingClientRect()?.height;

    const askAIContainer  = document.getElementById('ask-ai');
    const launcherTopPosition = askAIContainer?.getBoundingClientRect()?.top;
    
    const leftPos = editor.renderer.container.clientWidth;

    const element = document.getElementById("ask-ai-popup");
    
    if(element){
      const elementWidth = element?.getBoundingClientRect()?.width;

      element.style.maxHeight = `${parentContainerHeight/2}px`;
      element.style.left = `${leftPos - elementWidth - 8}px`;


      if(container && askAIContainer) {
        // parentContainerHeight and launcherTopPosition is used to check 
        // if the AI popup should be placed above/below the launcher
        // default behaviour is -> popup is placed below the launcher
        if((parentContainerHeight/2) > launcherTopPosition) {
          element.style.top = `${topPosition + 104}px`;
        } else {
          element.style.top = `${topPosition - 196}px`;
        }
      } else {
        element.style.top = `${topPosition + 104}px`;
      }
    }
  }

  const handleCallRestAPISnippet = (payload) => {
    const contextMenu = document.getElementById("context-menu");
    contextMenu.style.display = "none";
    const restAPIPayloadSnippet = `
/*
  * The response structure to the API is:
  *     response.Body  - {Object}
  *     response.Headers - {Object}
  *     response.StatusCode - {Number}
  *     response.error - {String}
*/`;
    const menuMode = contextMenu.getAttribute("data-menumode") ?? "create";
    const cursorPos = editor.getCursorPosition();
    const currentLineValue = editor.session.getLine(cursorPos.row);
    const callRestApiMethod = `rest_api_connector(connectorName='${
      payload.connectorName
    }', urlPath='', method='${payload.selectedMethod}', body=${returnBodyType(
      payload.contentType
    )}, headers={'Content-Type': '${payload.contentType}'}, queryParams={}); ${
      menuMode === "create" ? restAPIPayloadSnippet : ""
    }`;
    const updatedLineValue = currentLineValue.replace(
      "rest_api_connector(select_connector);",
      callRestApiMethod
    );

    editor.session.replace(
      {
        start: { row: cursorPos.row, column: 0 },
        end: { row: cursorPos.row, column: Number.MAX_VALUE },
      },
      updatedLineValue
    );

    if (!currentLineValue.includes("select_connector")) {
      editor.session.replace(
        {
          start: {
            row: cursorPos.row,
            column: findWordPosition(updatedLineValue, "rest_api_connector"),
          },
          end: { row: cursorPos.row, column: Number.MAX_VALUE },
        },
        callRestApiMethod
      );
    }
  };

  const replaceTokenWithValue = (inputString, token, replacement) => {
    return inputString.replace(new RegExp(token, "g"), replacement);
  };

  const handleMACROSnippet = (payload) => {
    const contextMenu = document.getElementById("token-menu");
    contextMenu.style.display = "none";
  
    const menuMode = contextMenu.getAttribute("data-menumode") ?? "create";
    const type = contextMenu.getAttribute("data-type");
    const cursorPos = editor.getCursorPosition();
    const currentLineValue = editor.session.getLine(cursorPos.row);
  
    // Find all occurrences of 'select_list_token' in the line
    const selectTokenIndices = [];
    let match;
    const tokenRegex = /select_(list|date|datetime)_token|select_unit/g;
    while ((match = tokenRegex.exec(currentLineValue)) !== null) {
      selectTokenIndices.push(match.index);
    }
  
    // Determine the nearest occurrence of 'select_list_token' to the cursor
    let nearestIndex;
    if (selectTokenIndices.length > 0) {
      nearestIndex = selectTokenIndices.reduce((prev, curr) => {
        return Math.abs(curr - cursorPos.column) <
          Math.abs(prev - cursorPos.column)
          ? curr
          : prev;
      });
    }

    // Function to get the method name based on the cursor position
    const getMethodName = (cursorPos) => {
      const lines = editor.session.doc.getAllLines();
      const currentLine = lines[cursorPos.row];
  
      // Check if the cursor is inside parentheses
      const beforeCursor = currentLine.substring(0, cursorPos.column);
      const afterCursor = currentLine.substring(cursorPos.column);
      const openParenIndex = beforeCursor.lastIndexOf('(');
      const closeParenIndex = afterCursor.indexOf(')');
  
      if (openParenIndex !== -1 && closeParenIndex !== -1) {
        // Search for the method name before the open parenthesis
        const methodLine = beforeCursor.substring(0, openParenIndex);
        const methodMatch = methodLine.match(/(\w+)\s*$/);
        if (methodMatch) {
          return methodMatch[1];
        }
      }
  
      return null;
    };

    const methodName = getMethodName(cursorPos);

    let replacementValue = "";
    // Construct the replacement value
    if(payload.tokenType.includes("list") && !["FILTER", "SORT", "COUNT", "DISTINCT"].includes(methodName)){
      replacementValue = `${payload.token.name.replace(/"/g, "")}${
        payload.selectedKey ? `, keyName='${payload.selectedKey}'` : ""
      }`;
    }
    if(payload.tokenType.includes("list") && ["FILTER"].includes(methodName)){
      replacementValue = `${payload.token.name.replace(/"/g, "")}${
        payload.selectedKey ? `,"" , "${payload.selectedKey}"` : ", ''"
      }`;
    }
    else if(payload.tokenType.includes("list") && ["SORT"].includes(methodName)){
      replacementValue = `${payload.token.name.replace(/"/g, "")}${
        payload.selectedKey ? `, "ASC", '${payload.selectedKey}'` : ', "ASC"'
      }`;
    }
    else if(payload.tokenType.includes("list") && ["COUNT", "DISTINCT"].includes(methodName)){
      replacementValue = `${payload.token.name.replace(/"/g, "")}`;
    }
    else if (payload.tokenType.includes("date") || payload.tokenType.includes("dateTime")){
      replacementValue = `"${payload.token.name.replace(/"/g, "")}"`;
    }
    else if (payload.tokenType.includes("unit")){
      replacementValue = `"${payload.token.name.replace(/"/g, "")}"`;
    }

    // Perform the replacement if there's a nearest occurrence
    if (nearestIndex !== undefined) {
      const updatedLineValue =
        currentLineValue.substring(0, nearestIndex) +
        replaceTokenWithValue(
          currentLineValue.substring(
            nearestIndex,
            nearestIndex + `${type}`.length
          ),
          `${type}`,
          replacementValue
        ) +
        currentLineValue.substring(nearestIndex + `${type}`.length);
  
      // Replace the line in the editor
      editor.session.replace(
        {
          start: { row: cursorPos.row, column: 0 },
          end: { row: cursorPos.row, column: Number.MAX_VALUE },
        },
        updatedLineValue
      );
  
      setOpenSelectTokenList(false);
    }
  };
  

  const findWordPosition = (str, wordToFind) => {
    var position = str.indexOf(wordToFind);
    return position;
  };

  const validateRestAPISignature = (value) => {
    const validatedFuncSignArray = validateFunctionSignature(value);
    if (validatedFuncSignArray.length > 0) {
      validatedFuncSignArray.forEach((i) => {
        setTokensAnnotationErrors(
          `Invalid signature for rest_api_connector().
   Expected 6 args.
   Passed ${i.numArguments} arguments.
   Missing '${i.missingArguments}' args.`,
          i.lineNumber - 1
        );
      });
    }
    return !validatedFuncSignArray.length > 0;
  };

  const validateListMACROSignatures = (value) => {
    const validatedHelperFuncSignArray = validateListMacrosSignature(
      sanitizedString(value, allAutocomplete, globalTokenMap)
    );
    if (validatedHelperFuncSignArray.length > 0) {
      validatedHelperFuncSignArray.forEach((i) => {
        setTokensAnnotationErrors(`${i.functionName} : ${i.errorMessage}`);
      });
    }
    return !validatedHelperFuncSignArray.length > 0;
  };

  const updateSelectedConnector = (connector) => {
    setSelectedConnector(connector);
  };

  const onJSONEditorChange = (data) => {
    const updatedData = structuredClone(data);
    findExecutedValue(JSON.stringify(updatedData), editorMode);
    // injectCompleters(globalCompleters, editorMode, false);
    setNoCodeData(data);
    updateEditorValueFromNoCode(data);
  };

  const closeOutputPopup = () => {
    setRunCodeData("");
  };

  const handleInfoMenu = () =>{
    if(runCodeData){
      setRunCodeData("");
    }
    setIsInfoMenuOpen(!isInfoMenuOpen)
  }

  const processValue = (value) => {
    // Extract tokens from the input value
    const extractedTokens = extractTokens(value);
    
    extractedTokens.forEach((token) => {
      // Token name includes the {{ and }}
      const tokenName = token;
    
      // Find token object in globalCompleters or globalTokenMap
      let tokenObj = globalCompleters.find((j) => j.value === tokenName);
      if (tokenObj === undefined) {
        tokenObj = globalTokenMap[tokenName];
      }
    
      if (tokenObj) {
        // Determine if the token should be quoted
        const shouldQuote = tokenObj.meta === "string";
        const isQuoted = new RegExp(`"${token.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}"`).test(value);
        if (shouldQuote && !isQuoted) {
          // Replace token with quoted token if not already quoted
          value = value.replace(token, `"${token}"`);
        } else if (!shouldQuote && isQuoted) {
          // Ensure token is not quoted
          value = value.replace(`"${token}"`, token);
        }
      }
    });
    
    return value;
  };

  /* 
    Function to handle quotes in case of boolean and numeric
  */
  const handleCodeStringForEditor = (value, mode = "addQuotes", from = "") => {
    let updatedValue = value;

    if(!updatedValue || updatedValue === ""){
      return updatedValue;
    }


    if (updatedValue !== "" && updatedValue !== undefined) {
      let extractedTokens = extractTokens(value);
      let alreadyProcessedTokensMap = {};
      const QuotesForDataTypes = ["numeric", "boolean", "array", "list", "number", "object", "boolean", "json"];
  
      extractedTokens.forEach((token, index) => {
        let tokenObj = globalCompleters.find((j) => j.value.replaceAll('"', "") === token) || globalTokenMap[token];
        if (tokenObj && tokenObj.meta !== "") {
          if (!alreadyProcessedTokensMap[token] && QuotesForDataTypes.includes(tokenObj.meta.toLowerCase())) {
            alreadyProcessedTokensMap[token] = true;
            if (mode === "replaceQuotes") {
              updatedValue = updatedValue.replaceAll(`"${token}"`, `${token}`);
            } else if (mode === "addQuotes") {
              const quotedTokenPattern = new RegExp(`"${token}"`);
              if (!quotedTokenPattern.test(updatedValue)) {
                updatedValue = updatedValue.replaceAll(token, `"${token}"`);
              }
            }
          }
          if (!alreadyProcessedTokensMap[token] && (tokenObj.meta.toLowerCase() === "string" || tokenObj.meta.toLowerCase() === "date" || tokenObj.meta.toLowerCase() === "datetime")) {
            alreadyProcessedTokensMap[token] = true;
            const quotedTokenPattern = new RegExp(`"${token}"`);
            if (!quotedTokenPattern.test(updatedValue)) {
              updatedValue = updatedValue.replace(new RegExp(`(${token})`, 'g'), `"$1"`);
            }
          }
        } else if (!alreadyProcessedTokensMap[token] && (tokenObj === undefined || tokenObj.meta === "")) {
          alreadyProcessedTokensMap[token] = true;
          if (mode === "replaceQuotes") {
            updatedValue = updatedValue.replaceAll(`"${token}"`, `${token}`);
          } else if (mode === "addQuotes") {
            const quotedTokenPattern = new RegExp(`"${token}"`);
            if (!quotedTokenPattern.test(updatedValue)) {
              updatedValue = updatedValue.replaceAll(token, `"${token}"`);
            }
          }
        }
      });
    }
  
    return updatedValue.replaceAll('"[', '[').replaceAll(']"', ']').replaceAll('\\"', '"');
  };
    
  const isToolbarDisabled = (!isErrorFree ||  (editor &&  editor.session.getValue() === ""));
  
  const isToolbarDisabledHelper = () =>{
    let isValidList = true;
    
    if(editor && editor.session.getValue() !== ""){
      try{
        if(editorMode === "list" && Array.isArray(nodeCodeData)){
          isValidList = true;
        }
        else if (editorMode === "json" && testJSON(nodeCodeData)){
          isValidList = true;
        }
        else{
          isValidList = false;
        }
      }
      catch(err){
        isValidList = false;
      }
    }

    return !(!isToolbarDisabled && isValidList);
  }

  const calculateEditorHeight = () => {
    if(runCodeData !== ""){
      return 50;
    }
    if(isInfoMenuOpen &&  ['js', 'formula'].includes(editorMode)){
      return 50;
    }

    return 100;
  }

  return (
    <div className="App">
      {editorMode !== "" && (
        <>
          <div className="nected-editor-container" style={{ height : `calc(${calculateEditorHeight()}vh - 3px)` }}>
            <div id='ask-ai-popup'>
              {suggestionType === 'askAI' && 
                <PopUp onClose={(e) => {
                  e.stopPropagation();
                  setSuggestionType('');
                }}>
                  <AskAISuggestion type='javascript' handleInsertSuggestion={handleInsertSuggestion} />
              </PopUp>}
            </div>
            <div
              id="nected-editor"
              style={{ display: displayMode === "code" ? "block" : "none" }}
            ></div>

            {displayMode === "gui" && (
              <NectedJSONEditor
                parentJSON={
                  (editor ? nodeCodeData : (editorMode === "list" ? '[]' : '{}'))
                }
                originalPayload={
                  (editor ? nodeCodeData : (editorMode === "list" ? '[]' : '{}'))
                }
                onChange={(data) => {
                  onJSONEditorChange(data);
                }}
                nectedSuggestions={allAutocomplete}
                globalTokenMap={globalTokenMap}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            )}
            {!isReadOnly &&
              <div className={`nected-editor-top-menu ${ isToolbarDisabled ? "disabled-menu" : ""}`}>
                <div className="top">
                {["js", "json", "list" , "formula"].includes(editorMode) && (
                  <div
                    className="execute-button"
                    data-tooltip-id="run-tooltip"
                    onClick={() => {
                      isToolbarDisabled ? null : executeJSFormula(globalCompleters, editorMode, "run");
                    }}
                  >
                    Test{" "}
                    {isRunCode ? <AiOutlineLoading3Quarters /> : <FaPlay />}
                  </div>
                )}
                {GUI_MODE.includes(editorMode) && (
                  <div className={isToolbarDisabledHelper() ? "display-mode-container disabled-menu-switch" : "display-mode-container"}>
                    <div
                      className={
                        displayMode === "code" ? "selected-display-mode" : ""
                      }
                      onClick={() => {
                        isToolbarDisabledHelper() ?  null : setDisplayMode("code");
                      }}
                    >
                      Raw
                    </div>
                    <div
                      className={
                        displayMode === "gui" ? "selected-display-mode" : ""
                      }
                      onClick={() => {
                        isToolbarDisabledHelper() ? null : setDisplayMode("gui") ;
                      }}
                    >
                      Pretty
                    </div>
                  </div>
                )}
                {editorTooltip[editorMode] && ['js', 'formula'].includes(editorMode) &&
                  <div
                    className="nected-formula-button"
                    data-tooltip-id="nected-formula-button"
                    onClick={handleInfoMenu}
                  >
                   <RiFormula />  Functions
                  </div>
                }
                 <>
                      {['js', 'formula'].includes(editorMode) && 
                        <div id='explain-code-ai'
                        onClick={() => {
                          setSuggestionType('explainCodeAI'); 
                        }}>
                        <img src={'/images/ask_ai.png'} alt="explain code"/> Explain code

                      </div>}
                      <div id='explain-code-ai-popup'>
                        {suggestionType === 'explainCodeAI' && 
                          <PopUp title="Explain Code" 
                            onClose={(e) => {
                              e.stopPropagation();
                              setSuggestionType('');
                            }}>
                            <ExplainCodeAI 
                              type='javascript' 
                              tokens={globalTokenMap} 
                              allAutocomplete={allAutocomplete}
                              setSuggestionType={setSuggestionType} 
                              code={editor?.session?.getValue() ?? ''}  
                            />
                        </PopUp>}
                      </div>
                    </>
                </div>
                <div className="bottom">
                  {editorTooltip[editorMode] && documentationLink && (
                    <div className="help-menu">
                      {/* {editorTooltip[editorMode] && ['js', 'formula'].includes(editorMode) &&
                        <>
                          <div onClick={handleInfoMenu}>
                            <span>Help</span>
                            <IoIosInformationCircle />
                          </div>
                          <div className="help-menu-divider"></div>
                        </>
                      } */}
                      
                      {documentationLink && (
                        <div
                          onClick={() =>
                            window.open(`${documentationLink}`, "_blank")
                          }
                        >
                          <span>Documentation</span>
                          <IoIosHelpCircle />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="right-container">
                    <img
                      className="nected-icon"
                      src={`/konark/website/icons/nected-icon.png`}
                      alt="nected-icon"
                    />
                  </div>
                </div>
              </div>
            }

          </div>
          {variant === "multi" && (
            <>
              <div
                className={`error-container ${
                  isErrorFree ? "editor-noerror" : "editor-error"
                }`}
              ></div>
            </>
          )}
        </>
      )}
      {/* Default Page  */}
      {editorMode === "" && (
        <div className="welcome-container">
          <div className="image-container">
            <img
              alt="nected-logo"
              className="nected-logo"
              src={`/konark/website/icons/logo.svg`}
            />
          </div>
        </div>
      )}
      {displayMode === "code" && (
        <>
          <ContextMenu
            styles={{}}
            connectors={restConnectors}
            callback={handleCallRestAPISnippet}
            updateSelectedConnector={updateSelectedConnector}
          />
         

          {['js', 'formula'].includes(editorMode) && <>
            <div id='ask-ai'
              onClick={() => {
                setSuggestionType('askAI'); 
                openAskAISuggestionBox();
              }}>
              <img src={'/images/ask_ai.png'} alt="alt-ai"/> Ask AI

              
            </div>

       
          </>}
          

          <SelectList
            styles={{}}
            allAutocomplete={allAutocomplete}
            callback={handleMACROSnippet}
            openSelectTokenList={openSelectTokenList}
          />
        </>
      )}

      <div id="token-tooltip">
        {tooltipValue.type === "array" || tooltipValue.type === "object" ? (
          <ReactJson src={tooltipValue.executedValue} />
        ) : (
          <pre>{`${tooltipValue.executedValue}`}</pre>
        )}
      </div>
  
        <>
          {runCodeData && (
            <OutputData
              executedData={runCodeData}
              closeOutputPopup={closeOutputPopup}
            />
          )}
          {(isInfoMenuOpen && ['js', 'formula'].includes(editorMode)) &&
            <InfoContainer 
              handleInfoMenu={handleInfoMenu} 
              editorTooltip={editorTooltip} 
              editorMode={editorMode} 
              handleExecuteFormula={executeJS}
              prepareCodeString={prepareCodeString}
              sanitizeStr={sanitizedString}
              mode={editorMode}
              allAutocomplete={allAutocomplete}
              preSelectedFormulaData={preSelectedFormulaData}
              resetPreSelectedFormulaData={resetPreSelectedFormulaData}
            />
          }
        </>

      
    </div>
  );
}

export default NectedEditor;

const OutputData = ({ executedData, closeOutputPopup }) => {
  const [isMinimized, setMinimized] = useState(false);
  const response = { ...executedData };
  const executionTime = response.executionTime;
  delete response.executionTime;

  const handleMinimized = () => {
    setMinimized(!isMinimized);
  };

  useEffect(() => {
    setMinimized(false);
  }, [JSON.stringify(executedData)]);

  return (
    <div
      className="execute-output-container"
      style={{ height: isMinimized ? "auto" : "50vh" }}
    >
      <div className="execute-heading" style={{ background : isMinimized ? "#dfe8f6" : "#eee" }}>
        <div>
          <b>Output</b>
        </div>
        <div className="execution-status" style={{ fontSize: 15 }}>
          {response.error ? (
            <div
              style={{ color: "red", display: "flex", alignItems: "center" }}
            >
              <IoMdCloseCircle />
            </div>
          ) : (
            <div
              style={{ color: "green", display: "flex", alignItems: "center" }}
            >
              <FaCheckCircle />
            </div>
          )}
        </div>
        {/* <div className="execution-time">Executed Time : {executionTime}</div> */}
      </div>
      <div
        className="execute-output-container-close"
        onClick={closeOutputPopup}
      >
        Close
      </div>
      {/* <div
        className="execute-output-container-minimize"
        onClick={handleMinimized}
      >
        {isMinimized ? (
          <MdOutlineKeyboardArrowUp />
        ) : (
          <MdOutlineKeyboardArrowDown />
        )}
      </div> */}
      {!isMinimized && (
        <div className="response">
          {typeof response.result === "object" && response.result !== null ? (
            <ReactJson src={response.result ?? response.error} />
          ) : (
            <div>{`${
              (response.result !== undefined && (response.result === null || response.result !== "")) ? response.result : response.error}`}</div>
          )}
        </div>
      )}
    </div>
  );
};

const InfoContainer = ({ editorTooltip, editorMode , handleInfoMenu ,
  sanitizeStr,
  mode,
  allAutocomplete,
  preSelectedFormulaData,
  resetPreSelectedFormulaData
}) => {
  const [selectedFormulaData , setSelectedFormulaData ] = useState(null);
  const [searchedFormula , setSearchedFormula] = useState("");
  const [formulaOutput , setFormulaOutput] = useState(null);
  // useEffect(() =>{
  //   setSelectedFormulaData(preSelectedFormulaData ?? documentationPropertiesData[0].formulas[0])
  // },[])


  useEffect(() =>{
    setSelectedFormulaData(preSelectedFormulaData  ?? documentationPropertiesData[0].formulas[0])
  },[JSON.stringify(preSelectedFormulaData)])


  const handleSelectFormula = (index , jindex) => {
      resetPreSelectedFormulaData();
      setSelectedFormulaData(documentationPropertiesData[index].formulas[jindex])
  }

  const handleCopy = (text) =>{
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    
    // Avoid scrolling to bottom
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';
    
    // Append textarea to the body
    document.body.appendChild(textarea);
    // Select the text
    textarea.focus();
    textarea.select();
    
    try {
        // Execute the copy command
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copy text command was ' + msg);
    } catch (err) {
        console.error('Oops, unable to copy', err);
    }
    
    // Remove the textarea
    document.body.removeChild(textarea);
  }

  const updateCodeValue = (value) =>{
    return value.includes('str') || value.includes('num') || value.includes('bool') || value.includes('date') || value.includes('dateTime') || value.includes('list')
  }

  const handleExecuteFormula = (value, exampleValue , index ,tokens, from="") =>{
    let testString = updateCodeValue(value) ?  `${exampleValue}; ${value}` : value;
    const payload = {
      language: "JS",
      snippet: 
          sanitizeStr(
            testString,
            tokens,
            globalTokenMap,
            ""
          ),
      mode
    };

    executeJS(payload).then((data) =>{
      if(from === "formula-editor"){
        setFormulaOutput(data.data)
      }else{
      document.getElementById(index).innerText = `Output : ${data.data.result}`;
      }
    }).catch((error) => {
      if(from === "formula-editor"){
        setFormulaOutput(error.data)
      }
      console.log("error", error)
    });
  }

  const applyFilter = (value) =>{
    if(value !== ""){
      const filteredData =  documentationPropertiesData[0].formulas.filter((i) => i.name.includes(value) && i.name.startsWith(value[0]))
      if(filteredData.length > 0){
        resetPreSelectedFormulaData();
        setSelectedFormulaData(filteredData[0]);
        if(document.getElementById(filteredData[0].name)){
          handleExecuteFormula(filteredData[0].name , "", 0, allAutocomplete ,"formula-editor")
          // document.getElementById(filteredData[0].name).scrollIntoView({ behavior: 'smooth' });
        }
      }
      else{
        handleExecuteFormula(value , "", 0, allAutocomplete ,"formula-editor")
      }
    }
  }

  const handleFormulaOnChange = (value) =>{
    setSearchedFormula(value); 
    applyFilter(value); 
  }

  return (
    <div className="editor-info-container">
      <div className="execute-heading">
        <div>
          <b>Nected Formulas</b>
        </div>
        <div className="execution-status" style={{ fontSize: 15 }}>
      </div>
      </div>
      <div
        className="execute-output-container-close"
        onClick={handleInfoMenu}
      >
        Close
      </div>
      <div className="two-section-info-container">
        {/* <FormulaInput value={searchedFormula} suggestions={allAutocomplete} onChange={handleFormulaOnChange} placeholder="Enter Your Formula" />
        {(searchedFormula.length > 0 && formulaOutput) &&
          <div className={`formula-output formula-output-${formulaOutput.error ? 'error' : 'success'}`}>
              {formulaOutput.error  ?? formulaOutput.result}
          </div>
        } */}
        <div className="info-items">
          <div className="container-one">
          {documentationPropertiesData.map((i,index) => {
            return(
              <div className="category" key={index}>
                <span className="category-name">{i.category}</span>
                <div className="formulas">
                  {i.formulas.map((j,jindex) =>{
                    return(
                      <div id={j.name}  className={`${j.name === selectedFormulaData?.name ? 'selected-' : ''}formula-name`} key={`${index}_${jindex}`} onClick={() => { handleSelectFormula(index, jindex) }}>
                        <div style={{fontSize: 15, display: 'flex'}}>{j.icon}</div>
                        <div>{j.name}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
          </div>
          {selectedFormulaData &&
            <div className="container-two">
              <span className="formula-name">{selectedFormulaData.icon} {selectedFormulaData.descriptionTitle}</span>
              <span className="formula-description">{selectedFormulaData.description} </span>
              <div className="examples-des-container">
                  <RenderMethods 
                    type = {selectedFormulaData.name}
                    tokens={allAutocomplete}
                    handleCopy={handleCopy}
                    handleExecuteFormula={handleExecuteFormula}
                  />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const RenderMethods = ({
  type,
  tokens,
  handleCopy,
  handleExecuteFormula
}) => {

  const [selectedToken , setSelectedToken] = useState("");
  let methodsData = MethodsMap[type];
  let filteredTokens = tokens.filter((i) => i.meta === type.toLowerCase());
  const selectedTokenValue = tokens.find((i) => i.value === selectedToken);

  useEffect(( ) => {
    setSelectedToken(methodsData[0].exampleValue)
  },[type])

  return (
    <>
      {/* <div className="sample-value">
        {ExampleString[type]}
        <select onChange={(e) => setSelectedToken(e.target.value)}>
          <option value={methodsData[0].exampleValue}>{methodsData[0].exampleValue}</option>
          {filteredTokens.map((i,index) =>{
            return(
              <option key={index} value={i.value}>{i.value.replaceAll('"', "")}</option>
            )
          })
          }
        </select>
        {(selectedToken && selectedToken !== methodsData[0].exampleValue && selectedTokenValue) &&
          <>
            &nbsp; {"//"} {`${selectedTokenValue.executedValue}`}
          </>
        }
      </div> */}
      {methodsData.map((i, index) => {
        return (
          <div className="methods-desc-section" key={index}>
            <p>
              <strong>{i.methodName}:</strong> {i.methodDesc}
            </p>
            <div className="code-section">
              <code>
                <pre>{i.codeString}</pre>
                <span id={`${type.toLowerCase()}_${index}`}><b>Output :</b>  {`${i.exampleReturn}`}</span>
              </code>
              {/* <div className="icons-container">
                <div className="execute-icon" onClick={() => { handleExecuteFormula(i.codeString ,`${ExampleString[type]}${selectedToken}` , `${type.toLowerCase()}_${index}` , filteredTokens)}}>
                  <FaPlay />
                </div>
                <div className="copy-icon" onClick={() => { handleCopy(i.codeString); }}>
                  <FaCopy />
                </div>
              </div> */}
            </div>
          </div>
        );
      })}
    </>
  );
};